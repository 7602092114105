import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "../redux/store";
import { useEffect } from "react";

import { useApi } from "../hooks/useApi";
import { useRedux } from "../hooks/useRedux";

import AuthenticationService from "../services/Authentication.service";

import { onClientReducer } from "../redux/slices/userReducer";
import { addAuthToken } from "../redux/slices/authTokenReducer";
import { IAccount } from "../interfaces/api/IAccount";

import { InformationRequirements } from "../pages/information-requirements";
import { AccountOpening } from "../pages/account-opening";
import { ValidateCode } from "../pages/validate-code";
import { IneConsent } from "../pages/ine-consent";

import { CaptureINE } from "../pages/capture-ine";
import { DataConfirmation } from "../pages/data-confirmation";
import { AddressCondition } from "../pages/address-condition";
import { ProofAddressCondition } from "../pages/proof-address-condition";
import { SuggestionCaptureReceipt } from "../pages/suggestion-capture-receipt";
import BlackLists from "../pages/black-lists";
import { InformationClientClient } from "../pages/information-client";
import { LaborInformationClient } from "../pages/labor-information";
import { UseAccountManagement } from "../pages/use-account-management";
import { BeneficiariesClient } from "../pages/beneficiaries";
import { BeneficiariesCheckClient } from "../pages/beneficiaries-check";
import { IdentificationQuestionnaire } from "../pages/identification-questionnaire";
import { LivenessCheck } from "../pages/liveness-check";
import { LivenessCheckConfirm } from "../pages/liveness-check-confirm";
import { ContractCondition } from "../pages/contract-condition";
import { IdentityValidation } from "../pages/identity-validation";
import { DateExpire } from "../pages/date-expire";
import { FinishProcess } from "../pages/finish-process";
import { FollowLater } from "../pages/follow-later";
import { UseAccountManagementUps } from "../pages/use-account-management/ups";
import { UseAccountManagementRiskResponse } from "../pages/use-account-management/risk-response";
import { VideoCaptureCondition } from "../pages/video-capture-condition";
import CustomizableViewOk from "../pages/customizable-view-ok";
import { RetakePhoneClient } from "../pages/retake-phone";
import { Cancelled } from "../pages/cancelled";
import { Completed } from "../pages/completed";
import { NoCredentials } from "../pages/no-credentials";
import Loader from "../components/atoms/loader";
import ProcessContracts from "../services/processContracts.service";
import { IDataProcess } from "../interfaces/api/IProcessContracts";
import {
  onFiscalFormation,
  onLaborInformation,
  onUseAccountManagement,
} from "../redux/slices/clientInformation";
import { returnDataSteps } from "../redux/slices/confirmDataReducer";
import { onBeneficiaries } from "../redux/slices/beneficiaries";
import { addData } from "../redux/slices/dataReducer";
import { videoRecordingData } from "../redux/slices/videoRecordingReducer";
import { PdfViewer } from "../pages/pdf-viewer";
import { TermsCondition } from "../pages/terms-conditions";
import { CustomerSignatureShow } from "../pages/customer-signature-show";
import { CustomerSignatureAction } from "../pages/customer-signature-action";
import { TelephoneContact } from "../pages/telephone-contact";

import Congratulation from "../pages/congratulation/congratulation";
import Test from "../pages/Test";



const persistor = persistStore(store);

const { getAuthOauthToken, getProcessesValidate, getProcessesCurrentProcessId } =
  new AuthenticationService();
const { getDataProcess } = new ProcessContracts();

const AppRoute = () => {

  const Authorization = localStorage.getItem("access_token")
    ? JSON.parse(localStorage.getItem("access_token") || "")
    : undefined;
  const access_uuid = localStorage.getItem("access_uuid")
    ? JSON.parse(localStorage.getItem("access_uuid") || "")
    : undefined;
  const access_reload = localStorage.getItem("access_reload")
    ? JSON.parse(localStorage.getItem("access_reload") || "no-authenticated")
    : "no-authenticated";


  const navigate = useNavigate();
  const location = useLocation();
  const { token } = queryString.parse(location.search);

  const {
    dispatch,
    data: {
      authTokenPersist: { authToken = Authorization },
      clientPersist: { uuid = access_uuid },
    },
  } = useRedux();

  const {
    data: dataAuthOauthToken,
    execute: executeSaveAuthOauthToken,
    isLoading: isAuthOauthToken,
    error: isErrorAuthOauthToken,
  } = useApi<IAccount, {}>(getAuthOauthToken);

  const {
    data: dataProcessesId,
    execute: executeProcessesId,
    isLoading: isProcessesId,
    error: isErrorProcessesId,
  } = useApi(getProcessesValidate);

  const {
    data: dataCurrentProcessId,
    execute: executeCurrentProcessId,
    isLoading: isCurrentProcessId,
    error: isErrorCurrentProcessId,
  } = useApi(getProcessesCurrentProcessId);

  const {
    data: isDataProcess,
    error: errorDataProcess,
    execute: execDataProcess,
    isLoading: isLoadingDataProcess,
  } = useApi<IDataProcess, { processId: string }>(getDataProcess);

  useEffect(() => {
    if (token && access_reload === "no-authenticated") {
      localStorage.clear();
      executeSaveAuthOauthToken({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && access_reload === "no-authenticated") {
      localStorage.setItem("uuid", token.toLocaleString().replace(" ", "+"));
      if (token && dataAuthOauthToken && !isAuthOauthToken && !isErrorAuthOauthToken) {
        dispatch(
          addAuthToken({
            authToken: dataAuthOauthToken.access_token,
            geoPosition: "23.634501:-102.552784",
            callerIP: "10.0.0.0",
          }),
        );
        executeProcessesId({
          token: token.toLocaleString().replace(" ", "+"),
          BearerToken: dataAuthOauthToken.access_token,
        });
        localStorage.setItem("access_token", JSON.stringify(dataAuthOauthToken.access_token));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dataAuthOauthToken, isAuthOauthToken, isErrorAuthOauthToken]);

  useEffect(() => {
    if (dataProcessesId && !isProcessesId && !isErrorProcessesId) {
      dispatch(
        onClientReducer({
          token: authToken,
          uuid: dataProcessesId.processId,
          bpId: dataProcessesId.bpId,
        }),
      );
      localStorage.setItem("access_uuid", JSON.stringify(dataProcessesId.processId));
      localStorage.setItem("access_callerip", JSON.stringify(dataProcessesId.callerIP));
      localStorage.setItem("access_geoposition", JSON.stringify(dataProcessesId.geoPosition));
      localStorage.setItem("access_useragent", JSON.stringify(dataProcessesId.userAgent));
      localStorage.setItem("access_bpId", JSON.stringify(dataProcessesId.bpId));
      localStorage.setItem("access_reload", JSON.stringify("authenticated"));
      window.location.reload();
    } else if (!dataProcessesId && !isProcessesId && isErrorProcessesId) {
      navigate("/error-credentials");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProcessesId, isProcessesId, isErrorProcessesId]);

  useEffect(() => {
    if (access_reload === "authenticated") {
      ///  Inicio redireccion cuando se inicia un nuevo proceso
      if (location.pathname.split("/")[1] === "start") {
        navigate("/InformationRequirements");
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: "GET_PROCESS_ID", data: { processId: uuid } }),
          );
        }
      } else if (location.pathname.split("/")[1] === "retake") {
        if (uuid) {
          execDataProcess({ processId: uuid });
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ type: "GET_PROCESS_ID", data: { processId: uuid } }),
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_reload]);

  /// Validacion del estado del proceso solo para la retoma
  useEffect(() => {
    if (isDataProcess && !isLoadingDataProcess && !errorDataProcess) {
      switch (isDataProcess.state) {
        case "IN_PROGRESS":
          executeCurrentProcessId({ processId: isDataProcess.processId });
          break;
        case "COMPLETED":
          navigate("/Completed");
          break;
        case "EXPIRED":
          navigate("/DateExpire");
          break;
        case "CANCELLED":
          navigate("/Cancelled");
          break;
      }
    } else if (!isLoadingDataProcess && errorDataProcess) {
      navigate("/NoCredentials");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataProcess, isLoadingDataProcess, errorDataProcess]);

  // Redirije al a la ultima pantalla en donde
  useEffect(() => {
    if (dataCurrentProcessId && !isCurrentProcessId && !isErrorCurrentProcessId) {
      switch (dataCurrentProcessId.resource) {
        case "CaptureINE":
          dispatch(addData({ ...dataCurrentProcessId.data.dataPersist }));
          break;
        case "AddressCondition":
          localStorage.setItem("condition", "true");
          const dateString = dataCurrentProcessId.data.confirmData.dateBirth;
          const dateObj = dateString?.replace(/(\d{4})-(\d{2})-(\d{2})/, "$1/$2/$3");
          dispatch(
            returnDataSteps({ ...dataCurrentProcessId.data.confirmData, dateBirth: dateObj }),
          );
          dispatch(addData({ ...dataCurrentProcessId.data.dataPersist }));
          break;
        case "ProofAddressCondition":
          localStorage.setItem("condition", "true");
          dispatch(
            returnDataSteps({
              ...dataCurrentProcessId.data.confirmData,
              ...dataCurrentProcessId.data.confirmData.credentialData,
              ...dataCurrentProcessId.data.confirmData.principalAddress,
            }),
          );
          dispatch(addData({ ...dataCurrentProcessId.data.dataPersist }));
          break;
        case "DataConfirmation":
          dispatch(
            returnDataSteps({
              ...dataCurrentProcessId.data.confirmData,
              ...dataCurrentProcessId.data.confirmData.credentialData,
              ...dataCurrentProcessId.data.confirmData.principalAddress,
            }),
          );
          dispatch(addData({ ...dataCurrentProcessId.data.dataPersist }));
          break;
        case "LaborInformation":
        case "FiscalInformationClient":
          dispatch(addData({ ...dataCurrentProcessId.data.dataPersist }));
          dispatch(onFiscalFormation({ ...dataCurrentProcessId.data.fiscalFormation }));
          dispatch(onLaborInformation({ ...dataCurrentProcessId.data.laborInformation }));
          break;
        case "UseAccountManagement":
        case "UseAccountManagement/ups":
        case "UseAccountManagement/management":
          dispatch(addData({ ...dataCurrentProcessId.data.dataPersist }));
          dispatch(onFiscalFormation({ ...dataCurrentProcessId.data.fiscalFormation }));
          dispatch(onLaborInformation({ ...dataCurrentProcessId.data.laborInformation }));
          dispatch(onUseAccountManagement({ ...dataCurrentProcessId.data.useAccountManagement }));
          break;
        case "BeneficiariesClient":
        case "BeneficiariesCheckClient":
          dispatch(addData({ ...dataCurrentProcessId.data.dataPersist }));
          dispatch(onBeneficiaries(dataCurrentProcessId.data.beneficiaries));
          break;
        case "VideoCaptureCondition":
          dispatch(addData({ ...dataCurrentProcessId.data.dataPersist }));
          dispatch(videoRecordingData({ ...dataCurrentProcessId.data.videoRecordingData }));
          break;
        case "LivenessCheck":
        case "IdentityValidation":
        case "IdentificationQuestionnaire":
        case "IneConsent":
        case "AccountOpening":
          dispatch(addData({ ...dataCurrentProcessId.data.dataPersist }));
          break;
      }
      navigate(`/${dataCurrentProcessId.resource}`);
    } else if (!isCurrentProcessId && isErrorCurrentProcessId) {
      navigate("/error-credentials");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurrentProcessId, isCurrentProcessId, isErrorCurrentProcessId]);

  if (isCurrentProcessId || isProcessesId || isLoadingDataProcess) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <PersistGate persistor={persistor}>
      {authToken && access_uuid && access_reload === "authenticated" ? (
        <Routes>
          <Route path="/InformationRequirements" element={<InformationRequirements />} />
          <Route path="/InformationRequirements/TermsCondition" element={<TermsCondition />} />
          <Route path="/AccountOpening" element={<AccountOpening />} />
          <Route path="/ValidateCode" element={<ValidateCode />} />
          <Route path="/IneConsent" element={<IneConsent />} />
          <Route path="/CaptureINE" element={<CaptureINE />} />
          <Route path="/AddressCondition" element={<AddressCondition />} />
          <Route path="/DataConfirmation" element={<DataConfirmation />} />
          <Route path="/ProofAddressCondition" element={<ProofAddressCondition />} />
          <Route path="/SuggestionCaptureReceipt" element={<SuggestionCaptureReceipt />} />
          <Route path="/BlackLists" element={<BlackLists />} />
          <Route path="/FiscalInformationClient" element={<InformationClientClient />} />
          <Route path="/LaborInformation" element={<LaborInformationClient />} />
          <Route path="/UseAccountManagement" element={<UseAccountManagement />} />
          <Route path="/UseAccountManagement/ups" element={<UseAccountManagementUps />} />
          <Route
            path="/UseAccountManagement/management"
            element={<UseAccountManagementRiskResponse />}
          />
          <Route path="/BeneficiariesClient" element={<BeneficiariesClient />} />
          <Route path="/BeneficiariesCheckClient" element={<BeneficiariesCheckClient />} />
          <Route path="/IdentificationQuestionnaire" element={<IdentificationQuestionnaire />} />
          <Route path="/LivenessCheck" element={<LivenessCheck />} />
          <Route path="/LivenessCheck/Confirm" element={<LivenessCheckConfirm />} />
          <Route path="/ContractCondition" element={<ContractCondition />} />
          <Route path="/CustomerSignatureShow" element={<CustomerSignatureShow />} />
          <Route path="/CustomerSignatureAction" element={<CustomerSignatureAction />} />
          <Route path="/Congratulation" element={<Congratulation />} />
          <Route path="/IdentityValidation" element={<IdentityValidation />} />
          <Route path="/TelephoneContact" element={<TelephoneContact />} />
          <Route path="/DateExpire" element={<DateExpire />} />
          <Route path="/FinishProcess" element={<FinishProcess />} />
          <Route path="/FollowLater" element={<FollowLater />} />
          <Route path="/VideoCaptureCondition" element={<VideoCaptureCondition />} />
          <Route path="/CustomizableViewOk" element={<CustomizableViewOk />} />
          <Route path="/Cancelled" element={<Cancelled />} />
          <Route path="/Completed" element={<Completed />} />
          <Route path="/NoCredentials" element={<NoCredentials />} />
          <Route path="/PdfViewer" element={<PdfViewer />} />
          <Route path="/Test" element={<Test />} />
          <Route
            path="*"
            element={
              <div className="loading">
                <Loader />
              </div>
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<NoCredentials />} />
          <Route path="" element={<NoCredentials />} />
          <Route path="/" element={<NoCredentials />} />
          <Route
            path="/start"
            element={
              <div className="loading">
                <Loader />
              </div>
            }
          />
          <Route
            path="/retake"
            element={
              <div className="loading">
                <Loader />
              </div>
            }
          />
          <Route path="/retake-phone" element={<RetakePhoneClient />} />
          <Route path="/NoCredentials" element={<NoCredentials />} />
          <Route path="/DateExpire" element={<DateExpire />} />
          <Route path="/Cancelled" element={<Cancelled />} />
          <Route path="/Completed" element={<Completed />} />
        </Routes>
      )}
    </PersistGate>
  );
};

export default AppRoute;
