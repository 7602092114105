import { IPersonalData } from "../../interfaces/api/IUtilsCommons";
import fetchBxiApiGateway from "./fetchBxiApiGateway";

type TTypeCommons =
  | "Parentesco"
  | "ProfesionTDD"
  | "RegimenFiscal"
  | "GiroAltoRiesgo"
  | "Nacionalidad"
  | "Pais"
  | "video-recording";

export const fetchUtilsCommonsCatalog = (typeCommons: TTypeCommons, processId: string) => {
  return fetchBxiApiGateway({
    url: `/utils-commons/common/${typeCommons}/catalog`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchUtilsCommonsZipCode = (zipCode: number | string, processId: string) => {
  return fetchBxiApiGateway({
    url: `/utils-commons/common/${zipCode}/zip-code`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchUtilsRfcCreator = (dataPerson: IPersonalData, processId: string) => {
  return fetchBxiApiGateway({
    url: `personal-data/person/rfc-creator`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
    data: {
      name1: dataPerson.name1,
      name2: dataPerson.name2,
      lastName1: dataPerson.lastName1,
      lastName2: dataPerson.lastName2,
      dateBirth: dataPerson.dateBirth,
    },
  });
};

export const fetchUtilsVideoRecording = (typeCommons: TTypeCommons, processId: string) => {
  return fetchBxiApiGateway({
    url: `/utils-commons/${typeCommons}`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchUtilsVideoRecordingWorkflows = (
  typeCommons: TTypeCommons,
  uuidVideo: string,
  processId: string,
) => {
  return fetchBxiApiGateway({
    url: `/utils-commons/${typeCommons}/workflows/${uuidVideo}`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchUtilsDinamicSurvey = (processId: string) => {
  return fetchBxiApiGateway({
    url: `/utils-commons/common/dinamic-survey`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
  });
};

export const fetchUtilsOcrReceiptAddress = (processId: string, dataFile: any) => {
  return fetchBxiApiGateway({
    url: `/utils-commons/ocr/receipt-address`,
    method: "POST",
    headers: {
      "process-id": processId,
    },
    data: {
      ...dataFile,
    },
  });
};

export const fetchUtilsRetakePhone = (processId: string, phone: string) => {
  return fetchBxiApiGateway({
    url: `/personal-data/person/phone/${phone}`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
    data: {},
  });
};

export const fetchUtilsCommonsFederalEntity = (processId: string, curp: string) => {
  return fetchBxiApiGateway({
    url: `personal-data/person/getFederalEntity/${curp}`,
    method: "GET",
    headers: {
      "process-id": processId,
    },
    data: {},
  });
};
