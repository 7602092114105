import * as React from "react";
import ic_csb_white from "../../assets/icons/ic_csb_white.png";
import headset_mic from "../../assets/icons/headset_mic.svg";
import Image from "../atoms/image";

const Header = (props: {
  style: React.CSSProperties | undefined;
  class: string;
  title: string;
  onClickEarphonesIcon: any;
}) => {
  return (
    <header
      className={`webkit-fill-available position-relative ${props.class}`}
      style={props.style}
    >
      <Image
        srcImage={ic_csb_white}
        className={"me-4"}
        alt={"ic_csb"}
        dataBsDismiss={undefined}
        styleImg={{ width: "3.5rem" }}
        onClick={undefined}
      />
      <strong
        style={{
          color: "white",
          fontSize: "14px",
          fontWeight: "500",
          fontStyle: " normal",
          lineHeight: "24px",
        }}
      >
        {props.title}
      </strong>
      <Image
        srcImage={headset_mic}
        className={"position-absolute top-0 end-0"}
        alt={"headset_mic"}
        dataBsDismiss={undefined}
        styleImg={{ cursor: "pointer" }}
        onClick={props.onClickEarphonesIcon}
      />
    </header>
  );
};

export default Header;
