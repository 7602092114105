import IUtilsCommons from "../../domain/services/IUtilsCommons.service";
import {
  fetchUtilsCommonsCatalog,
  fetchUtilsCommonsZipCode,
  fetchUtilsRfcCreator,
  fetchUtilsVideoRecording,
  fetchUtilsVideoRecordingWorkflows,
  fetchUtilsDinamicSurvey,
  fetchUtilsOcrReceiptAddress,
  fetchUtilsRetakePhone,
  fetchUtilsCommonsFederalEntity,
} from "./api/apiUtilsCommons";
import {
  ICatalog,
  IZipCode,
  IPersonalData,
  IVideoRecording,
} from "../interfaces/api/IUtilsCommons";

export default class UtilsCommons implements IUtilsCommons {
  async getRegimenFiscal({ processId }: { processId: string }): Promise<ICatalog[]> {
    return await fetchUtilsCommonsCatalog("RegimenFiscal", processId).then((r) => r.data);
  }
  async getParentesco({ processId }: { processId: string }): Promise<ICatalog[]> {
    return await fetchUtilsCommonsCatalog("Parentesco", processId).then((r) => r.data);
  }
  async getProfesionTDD({ processId }: { processId: string }): Promise<ICatalog[]> {
    return await fetchUtilsCommonsCatalog("ProfesionTDD", processId).then((r) => r.data);
  }
  async getGiroAltoRiesgo({ processId }: { processId: string }): Promise<ICatalog[]> {
    return await fetchUtilsCommonsCatalog("GiroAltoRiesgo", processId).then((r) => r.data);
  }
  async getNationality({ processId }: { processId: string }): Promise<ICatalog[]> {
    return await fetchUtilsCommonsCatalog("Nacionalidad", processId).then((r) => r.data);
  }
  async getCountries({ processId }: { processId: string }): Promise<ICatalog[]> {
    return await fetchUtilsCommonsCatalog("Pais", processId).then((r) => r.data);
  }
  async getZipCode({
    zipCode,
    processId,
  }: {
    zipCode: number | string;
    processId: string;
  }): Promise<IZipCode[]> {
    return await fetchUtilsCommonsZipCode(zipCode, processId).then((r) => r.data);
  }
  async getRfcByPerson({
    dataPerson,
    processId,
  }: {
    dataPerson: IPersonalData;
    processId: string;
  }): Promise<{ rfc: string; homoclave: string }> {
    return await fetchUtilsRfcCreator(dataPerson, processId).then((r) => r.data);
  }
  async postVideoRecording({ processId }: { processId: string }): Promise<IVideoRecording[]> {
    return await fetchUtilsVideoRecording("video-recording", processId).then((r) => r.data);
  }
  async getVideoRecordingWorkflows({
    uuidVideo,
    processId,
  }: {
    uuidVideo: string;
    processId: string;
  }): Promise<any> {
    return await fetchUtilsVideoRecordingWorkflows("video-recording", uuidVideo, processId).then(
      (r) => r.data,
    );
  }
  async gerUtilsDinamicSurvey({ processId }: { processId: string }): Promise<IVideoRecording[]> {
    return await fetchUtilsDinamicSurvey(processId).then((r) => r.data);
  }
  async posUtilsOcrReceiptAddress({
    processId,
    dataFile,
  }: {
    processId: string;
    dataFile: any;
  }): Promise<any> {
    return await fetchUtilsOcrReceiptAddress(processId, dataFile).then((r) => r.data);
  }
  async posUtilsRetakePhone({
    processId,
    phone,
  }: {
    processId: string;
    phone: string;
  }): Promise<string> {
    return await fetchUtilsRetakePhone(processId, phone).then((r) => r.data);
  }
  async getFederalEntity({ processId, curp }: { processId: string; curp: string }): Promise<any> {
    return await fetchUtilsCommonsFederalEntity(processId, curp).then((r: any) => r.data);
  }
}
