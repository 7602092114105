import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
//  Atoms
import Loader from "../../components/atoms/loader";
import Title from "../../components/atoms/titleV2";
// Molecules
import Select from "../../components/molecules/select";
// Templates
import { Footer } from "../../components/templates";
import Top from "../../components/templates/topV2";
// Redux
import { onLaborInformation } from "../../redux/slices/clientInformation";
import { useRedux } from "../../hooks/useRedux";
// Services
import UtilsCommonsService from "../../services/utilsCommons.service";
// Interfaces
import { ICatalog } from "../../interfaces/api/IUtilsCommons";
// Hooks 
import { useFollowLater } from "../../hooks/useFollowLater";
import { useModalError } from "../../hooks/useModalError";
import { useApi } from "../../hooks/useApi";
// Amplitude
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";
// Dictionaries
import Text from "../../utils/dictionaries/dictionary-laborInformation-client";
// schema
import validationSchema from "./schema";
import "./style.scss";

const UTILSCOMMONSSERRVICE = new UtilsCommonsService();
const { getProfesionTDD, getGiroAltoRiesgo } = UTILSCOMMONSSERRVICE;

const LaborInformationClient = () => {
  const {  modaComponente, setIsModalError } = useModalError();
  const [isLoadingRedirect, setLoadingRedirect] = useState(false);
  const { onClickContinue } = useFollowLater();

  const navigate = useNavigate();
  const {
    data: dataSelectOccupation = [],
    execute: executeProfesionTDD,
    isLoading: isLoadingProfesionTDD,
    error: isErrorProfesionTDD,
  } = useApi<ICatalog[], { processId: string }>(getProfesionTDD);
  const {
    data: dataSelectActivities = [],
    execute: executeGetGiroAltoRiesgo,
    isLoading: isLoadingGiroAltoRiesgo,
    error: isErrorGiroAltoRiesgo,
  } = useApi<ICatalog[], { processId: string }>(getGiroAltoRiesgo);

  const {
    data: {
      clientPersist: { uuid },
      clientInformation: { laborInformation, fiscalFormation },
      dataPersist,
    },
    dispatch,
  } = useRedux();

  const formik = useFormik({
    initialValues: {
      occupation: laborInformation.occupation,
      occupationKey: laborInformation.occupationKey,
      activities: laborInformation.activities,
      activitiesKey: laborInformation.activitiesKey,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
    enableReinitialize: true,
  });

  useEffect(() => {
    executeProfesionTDD({ processId: uuid });
    executeGetGiroAltoRiesgo({ processId: uuid });;
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (isErrorGiroAltoRiesgo && !isLoadingGiroAltoRiesgo) ||
      (isErrorProfesionTDD && !isLoadingProfesionTDD)
    ) {
      setIsModalError(true);
    }
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOk = () => {
    setLoadingRedirect(true);
    setTimeout(() => {
      dispatch(onLaborInformation({ ...formik.values }));
      onClickContinue({
        data: { laborInformation: formik.values, fiscalFormation, dataPersist },
        processId: uuid,
        resource: "LaborInformation",
        step: "CONTINUE",
      });
      trackAnalyticsEvent(
        AmplitudEventName.Capturar_Información_laboral_N4, {
          "Profesion": formik.values.occupation,
          "Giro_de_actividades": formik.values.activities,
        }
      )
      navigate("/UseAccountManagement");
      setLoadingRedirect(false);
    }, 1000);
  };

  const btnDisabled = (): boolean => {
    let retVal = true;

    if (formik.values.occupationKey && formik.values.activitiesKey) {
      retVal = false;
    } else {
      retVal = true;
    }

    return retVal;
  };

  const onClickButtonLink = () => {
    navigate("/FollowLater", {
      state: {
        processId: uuid,
        resource: "LaborInformation",
        step: "CONTINUE_AFTER",
        data: { laborInformation: formik.values, fiscalFormation, dataPersist },
      },
    });
    dispatch(onLaborInformation({ ...formik.values }));
  };

  if (isLoadingRedirect) return <Loader />;

  return (
    <>
      {isLoadingGiroAltoRiesgo && isLoadingProfesionTDD ? (
        <Loader />
      ) : (
        <>
          <Top
            titleHeader={Text.top.titleHeader}
            subTitle={Text.top.subTitle}
            displayLeftImageTitle={"d-block"}
            displayProgressBar={"d-block"}
            classHeader={"mt-2"}
            classProgressBar={"mt-3"}
            valueProgressBar={"50%"}
            onClickGoBackIcon={() => navigate("/FiscalInformationClient")}
          />

          <main className="container_form mb-4_3">
            <div className=" mb-4">
              <Title text={Text.title.titleText} className={"container_form__title"} />
            </div>
            <>
              <div className="mt-4">
                <Select
                  defaultValue={formik.values.occupationKey}
                  value={formik.values.occupationKey}
                  placeholder={Text.selectOccupation.title}
                  data={dataSelectOccupation.map((key) => ({
                    value: key.key,
                    label: key.description,
                  }))}
                  onChange={(e: any) => {
                    const occupation = dataSelectOccupation.find(
                      (data) => data.key === e.target.value,
                    );
                    formik.setFieldValue("occupation", occupation?.description);
                    formik.setFieldValue("occupationKey", e.target.value);
                  }}
                />
              </div>
              <div className="mt-4">
                <Select
                  defaultValue={formik.values.activitiesKey}
                  value={formik.values.activitiesKey}
                  placeholder={Text.selectActivities.title}
                  data={dataSelectActivities.map((key) => ({
                    value: key.key,
                    label: key.description,
                  }))}
                  onChange={(e: any) => {
                    const activity = dataSelectActivities.find(
                      (data) => data.key === e.target.value,
                    );
                    formik.setFieldValue("activities", activity?.description);
                    formik.setFieldValue("activitiesKey", e.target.value);
                  }}
                />
              </div>
            </>
          </main>
          <Footer
            activeSpinner={false}
            disabledButton={btnDisabled()}
            onClickButtonPrimary={() => onOk()}
            labelButtonPrimary={Text.button.labelButtonPrimary}
            labelButtonSecundary={Text.button.labelButtonSecundary}
            onClickButtonLink={() => onClickButtonLink()}
          />
        </>
      )}

      {modaComponente}
    </>
  );
};

export default LaborInformationClient;
