import axios from "axios";
import { API_GATEWAY } from "../../../env";
import { fetchAuthentication } from "./apiAuthentication";

const Authorization = localStorage.getItem("access_token")
  ? JSON.parse(localStorage.getItem("access_token") || "")
  : "";
const access_uuid = localStorage.getItem("access_uuid")
  ? JSON.parse(localStorage.getItem("access_uuid") || "")
  : "";
const access_callerip = localStorage.getItem("access_callerip")
  ? JSON.parse(localStorage.getItem("access_callerip") || "")
  : "";
const access_geoposition = localStorage.getItem("access_geoposition")
  ? JSON.parse(localStorage.getItem("access_geoposition") || "")
  : "";
const access_useragent = localStorage.getItem("access_useragent")
  ? JSON.parse(localStorage.getItem("access_useragent") || "")
  : "";
///"geoPosition": "100.52:-96:5",
//"callerIP": "182.369.36",
const client = axios.create({
  baseURL: API_GATEWAY,
  headers: {
    "X-GeoPosition": access_geoposition,
    "X-CallerIP": access_callerip,
    "Content-type": "application/json",
    Authorization: `Bearer ${Authorization}`,
    "process-id": `${access_uuid}`,
    "X-userAgent": `${access_useragent}`,
  },
});

client.interceptors.response.use(
  (r) => r,
  async (error) => {
    const originalRequest = error.config;
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 ) {
      originalRequest._retry = true;
      try {
        const response = await fetchAuthentication();
        const { access_token } = response.data;

        localStorage.setItem('access_token', JSON.stringify(access_token));

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        window.location.reload();
        return axios(originalRequest);
      } catch (error) {
        // Handle refresh token error or redirect to login
      }
    }  else {
     return axios(originalRequest);
    }
  },
);
export default client;
