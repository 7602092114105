import {
  fetchGetBlackLists,
  fetchGetDataINE,
  fetchGetFederalEntity,
  fetchPersonalData,
  getFetchPersonByBpId
} from "./api/apiPersonalData";
import IPersonalData from "../../domain/services/IPersonalData.service";
import { fetchFiscalInformation, fetchLaborAndUseAccountInformation } from "./api/apiPersonalData";
import {
  IFiscalFormation,
  ILaborInformation,
  IUseAccountManagement,
} from "../interfaces/api/IPersonData";
import { IConfirmData } from "../interfaces/api/IPersonalData";

export default class PersonalDataService implements IPersonalData {
  async getDataINE(processId: string): Promise<any> {
    return await fetchGetDataINE(processId);
  }
  async getBlackLists(processId: string): Promise<any> {
    return await fetchGetBlackLists(processId);
  }
  async postConfirmData(payload: IConfirmData, processId: string): Promise<any> {
    return await fetchPersonalData(payload, processId);
  }
  async postFiscalInformation({
    fiscalFormation,
    processId,
  }: {
    fiscalFormation: IFiscalFormation;
    processId: string;
  }): Promise<any> {
    return await fetchFiscalInformation(fiscalFormation, processId).then((r) => r.data);
  }
  async postLaborAndUseAccountInformation({
    laborInformation,
    useAccountManagement,
    processId,
  }: {
    laborInformation: ILaborInformation;
    useAccountManagement: IUseAccountManagement;
    processId: string;
  }): Promise<any> {
    return await fetchLaborAndUseAccountInformation(
      laborInformation,
      useAccountManagement,
      processId,
    ).then((r) => r.data);
  }
  async getFederalEntity(processId: string, curp: string): Promise<any> {
    return await fetchGetFederalEntity(processId, curp);
  }
  async getFetchPersonByBpId(bpId: string): Promise<any> {
    return await getFetchPersonByBpId(bpId);
  }
}
