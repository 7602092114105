import Input from "../../../components/molecules/inputV2";
import Select from "../../../components/molecules/select";
import { RadioToggleButton } from "../../../components/molecules/radio-toggle-buttons";
import Paragraph from "../../../components/atoms/paragraph";
import { ConfirmAddress } from "../confirm-address";
import { days, months, years } from "../../../utils/utils";
import { ICatalog } from "../../../interfaces/api/IUtilsCommons";
import { allowAplphabetic } from "../../../utils/patterns";

const ItemBeneficiariesClientClient = ({
  formik,
  i,
  dataParentesco,
  codePostRedux,
}: {
  formik: any;
  i: number;
  dataParentesco: ICatalog[];
  codePostRedux: string;
}) => {
  return (
    <div className="accordion-body mb-4_3" key={i}>
      <div className="mt-4">
        <Input
          text={"Nombre(s)"}
          value={formik.values.beneficiaries[i]?.name}
          onChange={(e: any) => {
            if (allowAplphabetic.test(e.target.value)) {
              formik.setFieldValue(`beneficiaries[${i}].name`, e.target.value.toUpperCase());
            }
          }}
          maxLength={250}
          className={"form-control"}
          placeholder={"Nombre(s)"}
          type="text"
          id="names"
        />
      </div>
      <div className="mt-4">
        <Input
          text={"Apellido Paterno"}
          value={formik.values.beneficiaries[i]?.fatherLastName}
          onChange={(e: any) => {
            if (allowAplphabetic.test(e.target.value)) {
              formik.setFieldValue(
                `beneficiaries[${i}].fatherLastName`,
                e.target.value.toUpperCase(),
              );
            }
          }}
          className={"form-control"}
          placeholder={"Apellido Paterno"}
          type="text"
          id="fatherLastName"
          maxLength={150}
        />
      </div>
      <div className="mt-4">
        <Input
          value={formik.values.beneficiaries[i]?.motherLastName}
          text={"Apellido Materno"}
          onChange={(e: any) => {
            if (allowAplphabetic.test(e.target.value)) {
              formik.setFieldValue(
                `beneficiaries[${i}].motherLastName`,
                e.target.value.toUpperCase(),
              );
            }
          }}
          className={"form-control"}
          placeholder={"Apellido Materno"}
          type="text"
          maxLength={150}
          id="motherLastName"
        />
      </div>
      <div className="mt-4">
        <Select
          value={formik.values.beneficiaries[i]?.relationshipKey}
          placeholder={"Parentesco"}
          data={dataParentesco.map(({ description, key }, i) => ({
            value: key,
            label: description.toUpperCase(),
          }))}
          onChange={(e: any) => {
            if (dataParentesco) {
              const Parentesco = dataParentesco.find((memo) => memo.key === e.target.value);
              formik.setFieldValue(`beneficiaries[${i}].relationshipKey`, e.target.value);
              formik.setFieldValue(
                `beneficiaries[${i}].relationship`,
                Parentesco?.description.toUpperCase(),
              );
            }
          }}
        />
      </div>
      <div className="mt-4">
        <Paragraph text={"Fecha de nacimiento"} className={"mt-3"} styleParagraph={undefined} />
        <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
        <Select
            value={formik.values.beneficiaries[i]?.date.day}
            error={formik.values.beneficiaries[i]?.date.monthError}
            placeholder={"Día"}
            data={days().map((data) => ({ value: data.value, label: data.value }))}
            onChange={(e: any) => {
              formik.setFieldValue(`beneficiaries[${i}].date.day`, e.target.value);
              if (
                (["30", "31"].includes(e.target.value) && formik.values.beneficiaries[i]?.date.month === "02") ||
                (["04", "06", "09", "11"].includes(formik.values.beneficiaries[i]?.date.month) && e.target.value === "31")
              ) {
                formik.setFieldValue(`beneficiaries[${i}].date.dayError`, true);
              } else  {
                formik.setFieldValue(`beneficiaries[${i}].date.dayError`, false);
              }
            }}
          />
          <Select
            value={formik.values.beneficiaries[i]?.date.month}
            placeholder={"Mes"}
            error={formik.values.beneficiaries[i]?.date.monthError}
            data={months().map((data) => ({ value: data.value, label: data.label }))}
            onChange={(e: any) => {
              formik.setFieldValue(`beneficiaries[${i}].date.month`, e.target.value);
              if (
                (e.target.value === "02" && ["30", "31"].includes(formik.values.beneficiaries[i]?.date.day)) ||
                (["04", "06", "09", "11"].includes(e.target.value) && formik.values.beneficiaries[i]?.date.day === "31")
              ) {
                formik.setFieldValue(`beneficiaries[${i}].date.monthError`, true);
              } else {
                formik.setFieldValue(`beneficiaries[${i}].date.monthError`, false);
              }
            }}
          />

          <Select
            value={formik.values.beneficiaries[i]?.date.year}
            placeholder={"Año"}
            data={years().map((data) => ({ value: data.value, label: data.label }))}
            onChange={(e: any) =>
              formik.setFieldValue(`beneficiaries[${i}].date.year`, e.target.value.toUpperCase())
            }
          />
        </div>
      </div>

      <Paragraph
        text={"¿El beneficiario tiene el mismo domicilio que tu? "}
        className={"mt-3"}
        styleParagraph={undefined}
      />
      <div className="mb-4_3">
        <RadioToggleButton
          id={`${i}`}
          text={"Si"}
          value={"Si"}
          type={"radio"}
          name={"sameAddress"}
          onChange={() => formik.setFieldValue(`beneficiaries[${i}].sameAddress`, "yes")}
          isSelected={formik.values.beneficiaries[i]?.sameAddress === "yes"}
          className="radioToggleButton me-3"
          icon={undefined}
          displayImg="d-none"
          disabledInput={undefined}
          divStyle={undefined}
          labelStyle={{ paddingRight: "50px", paddingLeft: "50px" }}
          onClick={() => formik.setFieldValue(`beneficiaries[${i}].sameAddress`, "yes")}
        />
        <RadioToggleButton
          id={`${i}`}
          text={"No"}
          value={"No"}
          type={"radio"}
          name={"sameAddress"}
          onChange={() => formik.setFieldValue(`beneficiaries[${i}].sameAddress`, "no")}
          isSelected={formik.values.beneficiaries[i]?.sameAddress === "no"}
          className="radioToggleButton"
          icon={undefined}
          displayImg="d-none"
          disabledInput={undefined}
          divStyle={undefined}
          labelStyle={{ paddingRight: "50px", paddingLeft: "50px" }}
          onClick={() => formik.setFieldValue(`beneficiaries[${i}].sameAddress`, "no")}
        />
      </div>
      {formik.values.beneficiaries[i]?.sameAddress === "no" && (
        <ConfirmAddress i={i} formik={formik} codePostRedux={codePostRedux} />
      )}
    </div>
  );
};

export default ItemBeneficiariesClientClient;
