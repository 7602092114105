import { API_GATEWAY } from "../../env";

export var Config = (function () {
  const Authorization = localStorage.getItem("access_token")
    ? JSON.parse(localStorage.getItem("access_token") || "")
    : "";
  const access_uuid = localStorage.getItem("access_uuid")
    ? JSON.parse(localStorage.getItem("access_uuid") || "")
    : "";
  const access_callerip = localStorage.getItem("access_callerip")
    ? JSON.parse(localStorage.getItem("access_callerip") || "")
    : "";
  const access_geoposition = localStorage.getItem("access_geoposition")
    ? JSON.parse(localStorage.getItem("access_geoposition") || "")
    : "";
  const access_useragent = localStorage.getItem("access_useragent")
    ? JSON.parse(localStorage.getItem("access_useragent") || "")
    : "";
  // REQUIRED
  // The URL to call to process FaceTec SDK Sessions.
  // In Production, you likely will handle network requests elsewhere and without the use of this variable.
  // See https://dev.facetec.com/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.

  const DeviceKeyIdentifier = "dnTDuwQzkgM5F1IeW6AMFOLOwPWf18p7";

  const PublicFaceScanEncryptionKey =
  "-----BEGIN PUBLIC KEY-----\n" +
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2mGTjofP2SqUPxV4298d\n" +
  "j+lsixdv+Bmrue4L1DDqY0NUqtUWE1vNiumt7C7YOiGRRY4zVLwhu82AADIEqUjD\n" +
  "k9+EkzHSp0umdSJOYoUxRNm/dQG+f61x5hxTzrBysYgo4IYtUjZ+zhvRTO8Uksa0\n" +
  "IH8JH32E1TOPt3bjvWqKKmAWtm5jyzoAg/WktqwKCXXmmtRsTSqAvC65WXcrCMBp\n" +
  "pWp8UIr/z8AjjKS3jNRndTdr1uBGuR+pBNn/LajwYuul1b8I+LYrNFvlljNqMh0m\n" +
  "95pkhrtLF7eKR7vWLDqBwEfwhA4f3FNOib5+aiIDH1RcJD7bOrCswdn5r31Oo+fI\n" +
  "vQIDAQAB\n" +
  "-----END PUBLIC KEY-----";

  var ProductionKey = {}

  const BaseURL = `${API_GATEWAY}/utils-commons/facetec`
  const urlGeneral = `${API_GATEWAY}`

  const AuthToken = Authorization;
  const ProcessID = access_uuid;
  const GeoPosition = access_geoposition;
  const CallerIP = access_callerip;

  // -------------------------------------
  // REQUIRED
  // The FaceScan Encryption Key you define for your application.
  // Please see https://dev.facetec.com/facemap-encryption-keys for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.

  return {
    DeviceKeyIdentifier,
    BaseURL,
    PublicFaceScanEncryptionKey,
    ProductionKey,
    AuthToken,
    ProcessID,
    GeoPosition,
    CallerIP,
    urlGeneral
  };

})();