const listText = {
  top: {
    id: "top-component",
    titleHeader: "Apertura de cuenta",
    subTitle: "Información del cliente",
  },
  title: {
    id: "title",
    titleText: "Uso y manejo de cuenta",
  },
  accountType: {
    id: "t3",
    title: "¿Estas actuando por cuenta propia o a nombre de un tercero?",
    inputTextYes: "Cuenta propia",
    inputTextNo: "Cuenta de tercero",
  },
  myAccount: {
    id: "t3",
    title: "¿Tu eres el dueño de los recursos y quién obtendrá los beneficios de esta cuenta?",
    inputTextYes: "Si",
    inputTextNo: "No",
  },
  accountFamily: {
    id: "t3",
    title:
      "Confirmas que tú o alguno de tus familiares en línea directa NO desesmpeñan o han desempeñado Funciones Públicas destacadas en México o en el extranjero.",
    inputTextYes: "Confirmo",
    inputTextNo: "No confirmo",
  },
  titleAndImage: {
    title: "¿Qué son las funciones públicas destacadas?",
    bottomSheetMessage: {
      title: "¿Qué son las funciones públicas destacadas?",
      descrition:
        "Las funciones públicas destacadas son: jefes de estado o gobierno, líderes políticos, funcionarios de gobierno, judiciales o militares, miembros importantes de partidos políticos y organizaciones internacionales.",
    },
  },
  accountUse: {
    title: "¿Qué uso se le dará a la cuenta?",
    textArea: {
      title: "Especifica el uso que se le dará a la cuenta:",
    },
  },
  accountOrigin: {
    title: "Origen de los recursos",
  },
  accountMoney: {
    title: "¿Cuál es el monto de tus depósitos mensuales? ",
    description:
      "Puedes utilizar la barra para seleccionar el monto aproximado o ingresarlo directamente en el campo.",
    bottomSheetMessage: {
      title: "Monto aproximado (MXN)",
    },
    alert: {
      message:
        "Recuerda que esta cuenta no podrá recibir depósitos mayores a $232,000MX (Aproximadamente a 30,000 UDIs) mensuales.",
    },
  },
  button: {
    labelButtonPrimary: "Continuar",
    labelButtonSecundary: "Seguir más tarde",
  },
};

export default listText;
