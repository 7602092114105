import { Label } from "../atoms/label";
import TitleAndImage from "./title-and-image";

export default function InputGroupAction(props: any) {
  return (
    <>
      <div className={"input-group has-validation " + props.clasNameInputGroup}>
        <div className={"form-floating " + props.clasNameForm}>
          <input
            className={"form-control " + props.clasNameInput}
            id={props.id}
            placeholder={props.placeholder}
            type={props.type}
            name={props.name}
            value={props.value}
            disabled={props.disabled}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
              if (
                props.type === "number" &&
                (e.key === "ArrowLeft" ||
                  e.key === "ArrowRight" ||
                  e.key === "ArrowUp" ||
                  e.key === "ArrowDown" ||
                  e.key == "." ||
                  e.key === "-" ||
                  e.key === "+" ||
                  e.key === "e")
              ) {
                e.preventDefault();
              }
            }}
            style={{
              borderRight: "1px solid transparent",
              borderRadius: "6px 0px 0px 6px",
            }}
          />
          <Label text={props.text} htmlFor={props.htmlFor} className={props.displayLabel}></Label>
        </div>
        <span
          className="input-group-text bg-transparent"
          style={{
            borderLeft: "1px solid transparent",
            borderRadius: "0px 6px 6px 0px",
          }}
        >
          <TitleAndImage
            srcImage={props.srcImage}
            titleText={props.titleText}
            displayLeftImageTitle={props.displayLeftImageTitle}
            classTitle={props.classTitle}
            styleTitle={props.styleTitle}
            onClickTitleImgeDiv={props.onClickTitleImgeDiv}
          />
        </span>
        {props.error && <div className="invalid-feedback dangerous-red">{props.error}</div>}
      </div>
    </>
  );
}
