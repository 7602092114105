import { Config } from "../Config";
import { FaceTecSDK } from "../../assets/core-sdk/FaceTecSDK.js/FaceTecSDK";
import { FaceTecCustomization } from "../../assets/core-sdk/FaceTecSDK.js/FaceTecCustomization";

import { LivenessCheckProcessor } from "./processors/LivenessCheckProcessor";
import { SampleAppUtilities } from "./utilities/SampleAppUtilities";

import { PhotoIDScanProcessor } from "./processors/PhotoIDScanProcessor";
import { EnrollmentProcessor } from "./processors/EnrollmentProcessor";
import { APIKEY, API_GATEWAY } from "../../../env";

export var SampleApp = (function () {
  var latestEnrollmentIdentifier = "";
  var latestSessionResult = null;
  var latestIDScanResult = null;
  var latestProcessor: any;
  var imagesDirectory = "../../assets/core-sdk/FaceTec_images/";

  const darkBlue = "#00376F"; // blue
  const secondaryColor = "#00376F"; // blue
  const white = "#FFFFFF"; // white
  const font = "Roboto, sans-serif";
  const borderColor = " #5A97D5"; //border blue frame
  const backgroundColorOrange = "#F68A33"; //Background orange
  const disabledColor = "#A9AEBB"; //gray
  const logoCSB = "../../../../core-sdk/FaceTec_images/FaceTec_your_app_logo.png";

  var activityIndicatorSVG: SVGElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "svg",
  );
  activityIndicatorSVG.setAttribute("viewBox", "0 0 52 52");
  activityIndicatorSVG.classList.add("pseudo-fullscreen-activity-indicator-svg");
  activityIndicatorSVG.innerHTML = "<circle class='path' cx='26' cy='26' r='22'></circle>";

  var successResultAnimationSVG: SVGElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "svg",
  );
  successResultAnimationSVG.setAttribute("viewBox", "0 0 80 80");
  successResultAnimationSVG.classList.add("pseudo-fullscreen-success-svg");
  successResultAnimationSVG.innerHTML =
    "<circle cx='40.224' cy='40' r='33.3333' fill='white'/><path d='M40.224 73.3333C49.0645 73.3333 57.543 69.8214 63.7942 63.5702C70.0454 57.319 73.5573 48.8405 73.5573 40C73.5573 31.1594 70.0454 22.6809 63.7942 16.4297C57.543 10.1785 49.0645 6.66663 40.224 6.66663C31.3834 6.66663 22.9049 10.1785 16.6537 16.4297C10.4025 22.6809 6.89063 31.1594 6.89062 40C6.89063 48.8405 10.4025 57.319 16.6537 63.5702C22.9049 69.8214 31.3834 73.3333 40.224 73.3333ZM54.9375 33.8802L38.2708 50.5468L36.0573 52.7604L33.8438 50.5468L25.5104 42.2135L23.2969 40L27.724 35.5859L29.9375 37.7994L36.0573 43.9192L50.5104 29.4531L52.724 27.2395L57.138 31.6666L54.9245 33.8802H54.9375Z' fill='#33CD5E'/>";

  var unsuccessResultAnimationSVG: SVGElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "svg",
  );
  unsuccessResultAnimationSVG.setAttribute("viewBox", "0 0 80 80");
  unsuccessResultAnimationSVG.classList.add("pseudo-fullscreen-unsuccess-svg");
  unsuccessResultAnimationSVG.innerHTML =
    "<rect x='0.445312' width='80' height='80' rx='40' fill='#E2F0FF'/><g clip-path='url(#clip0_2976_20690)'><path d='M40.4472 27.2266C40.8863 27.2266 41.2456 27.5858 41.2456 28.0249V43.9918C41.2456 44.4309 40.8863 44.7901 40.4472 44.7901C40.0081 44.7901 39.6489 44.4309 39.6489 43.9918V28.0249C39.6489 27.5858 40.0081 27.2266 40.4472 27.2266ZM42.0439 49.5802C42.0439 50.4583 41.3254 51.1768 40.4472 51.1768C39.5691 51.1768 38.8505 50.4583 38.8505 49.5802C38.8505 48.702 39.5691 47.9835 40.4472 47.9835C41.3254 47.9835 42.0439 48.702 42.0439 49.5802ZM49.0294 17.6465C51.5342 17.6465 53.8694 18.9538 55.1667 21.0953L64.3975 36.2678C65.7946 38.563 65.7946 41.4371 64.3975 43.7323L55.1667 58.9008C53.8694 61.0463 51.5342 62.3536 49.0294 62.3536H31.7752C29.3603 62.3536 27.0251 61.0463 25.7278 58.9008L16.493 43.7323C15.0961 41.4371 15.0961 38.563 16.493 36.2678L25.7278 21.0953C27.0251 18.9538 29.3603 17.6465 31.7752 17.6465H49.0294ZM17.8572 37.0961C16.7704 38.8824 16.7704 41.1177 17.8572 42.904L27.085 58.0725C28.1029 59.7391 29.9091 60.7569 31.7752 60.7569H49.0294C50.9853 60.7569 52.7916 59.7391 53.7995 58.0725L63.0403 42.904C64.128 41.1177 64.128 38.8824 63.0403 37.0961L53.7995 21.9256C52.7916 20.2601 50.9853 19.2432 49.0294 19.2432H31.7752C29.9091 19.2432 28.1029 20.2601 27.085 21.9256L17.8572 37.0961Z' fill='#5A6172'/></g>";

  // Wait for onload to be complete before attempting to access the Browser SDK.
  window.onload = async function () {
    // Set a the directory path for other FaceTec Browser SDK Resources.
    FaceTecSDK.setResourceDirectory("../../../../core-sdk/FaceTecSDK.js/resources");

    // Set the directory path for required FaceTec Browser SDK images.
    FaceTecSDK.setImagesDirectory("../../../../core-sdk/FaceTec_images");

    // Initialize FaceTec Browser SDK and configure the UI features.

    //Get facetec keys
    var ProductionKey = await fetchAuthentication();

    if (ProductionKey) {
      FaceTecSDK.initializeInProductionMode(
        ProductionKey,
        Config.DeviceKeyIdentifier,
        Config.PublicFaceScanEncryptionKey,
        function (initializedSuccessfully) {
          if (initializedSuccessfully) {
            SampleAppUtilities.enableAllButtons();
            setAppTheme();

            var FaceTecStrings = {
              FaceTec_action_ok: "Entendido",
              FaceTec_action_im_ready: "Estoy listo",
              FaceTec_action_try_again: "Volver a intentarlo",
              FaceTec_action_continue: "Continuar",
              FaceTec_action_take_photo: "Tomar foto",
              FaceTec_action_accept_photo: "Aceptar",
              FaceTec_action_retake_photo: "Volver",
              FaceTec_action_confirm: "Confirmar información",

              FaceTec_accessibility_cancel_button: "Cancelar",
              FaceTec_accessibility_tap_guidance:
                "Toque dos veces cualquier parte de la pantalla para ver indicaciones sobre cómo alinear el rostro.",
              FaceTec_accessibility_key_down_guidance:
                "Presione Entrar o la barra espaciadora para obtener orientación sobre la alineación de la cara",

              FaceTec_accessibility_feedback_move_phone_away: "Su rostro está demasiado cerca",
              FaceTec_accessibility_feedback_move_phone_closer: "Su rostro está demasiado lejos",
              FaceTec_accessibility_feedback_face_too_far_left:
                "Su rostro está demasiado a la izquierda",
              FaceTec_accessibility_feedback_face_too_far_right:
                "Su rostro está demasiado a la derecha",
              FaceTec_accessibility_feedback_face_too_low: "Su rostro está demasiado abajo",
              FaceTec_accessibility_feedback_face_too_high: "Su rostro está demasiado arriba",
              FaceTec_accessibility_feedback_face_rotated_too_far_left:
                "Su rostro gira demasiado a la izquierda",
              FaceTec_accessibility_feedback_face_rotated_too_far_right:
                "Su rostro gira demasiado a la derecha",
              FaceTec_accessibility_feedback_face_looking_too_far_left:
                "Su rostro está demasiado inclinado hacia la izquierda",
              FaceTec_accessibility_feedback_face_looking_too_far_right:
                "Su rostro está demasiado inclinado hacia la derecha",
              FaceTec_accessibility_feedback_face_not_in_camera: "El rostro no se ve en la cámara",
              FaceTec_accessibility_feedback_hold_phone_to_eye_level:
                "Mantenga el dispositivo a la altura de los ojos",
              FaceTec_accessibility_feedback_move_away_web: "Cámara demasiado cerca",
              FaceTec_accessibility_feedback_move_closer_web: "Cámara demasiado lejos",
              FaceTec_accessibility_feedback_hold_to_eye_level_web:
                "Mover la cámara al nivel de los ojos",

              FaceTec_presession_frame_your_face: "Encuadre su rostro en el óvalo",
              FaceTec_presession_look_straight_ahead: "Mire hacia el frente",
              FaceTec_presession_hold_steady3: "Manténgase quieto durante: 3",
              FaceTec_presession_hold_steady2: "Manténgase quieto durante: 2",
              FaceTec_presession_hold_steady1: "Manténgase quieto durante: 1",
              FaceTec_presession_eyes_straight_ahead: "Mire hacia el frente",
              FaceTec_presession_remove_dark_glasses: "Quítese los lentes oscuros",
              FaceTec_presession_neutral_expression: "Expresión neutra, sin sonreír",
              FaceTec_presession_conditions_too_bright: "Demasiada luz",
              FaceTec_presession_brighten_your_environment: "Ilumine su entorno",

              FaceTec_feedback_center_face: "Centre su rostro",
              FaceTec_feedback_face_not_found: "Encuadre su rostro",
              FaceTec_feedback_move_phone_away: "Apártese",
              FaceTec_feedback_move_away_web: "Apártese",
              FaceTec_feedback_move_phone_closer: "Acérquese",
              FaceTec_feedback_move_web_closer: "Acerca la cara y rellena el óvalo",
              FaceTec_feedback_move_web_even_closer: "Aún Más Cerca",
              FaceTec_feedback_move_phone_to_eye_level: "Coloque la cámara a la altura de los ojos",
              FaceTec_feedback_move_to_eye_level_web: "Mira Directamente A La Cámara",
              FaceTec_feedback_face_not_looking_straight_ahead: "Mire hacia el frente",
              FaceTec_feedback_face_not_upright: "Mantenga la cabeza recta",
              FaceTec_feedback_face_not_upright_mobile: "Mantenga la cabeza recta",
              FaceTec_feedback_hold_steady: "Quédese quieto",
              FaceTec_feedback_use_even_lighting: "Ilumine el rostro de forma más uniforme",

              FaceTec_instructions_header_ready_desktop: "Prepárese para su videoselfie",
              FaceTec_instructions_header_ready_1_mobile: "Prepárese para",
              FaceTec_instructions_header_ready_2_mobile: "su videoselfie",
              FaceTec_instructions_message_ready_desktop:
                'Encuadre su rostro en el óvalo, Presione "Estoy listo" y acérquese',
              FaceTec_instructions_message_ready_1_mobile: "Encuadre su rostro en el óvalo",
              FaceTec_instructions_message_ready_2_mobile: 'Presione "Estoy listo" y acérquese',

              FaceTec_retry_header: "Intentémoslo otra vez",
              FaceTec_retry_subheader_message: "Necesitamos una videoselfie más nítida",
              FaceTec_retry_your_image_label: "Su selfie",
              FaceTec_retry_ideal_image_label: "Pose ideal",
              FaceTec_retry_instruction_message_1: "Sin reflejos ni luces intensas",
              FaceTec_retry_instruction_message_2: "Expresión neutra, sin sonreír",
              FaceTec_retry_instruction_message_3: "Demasiado borrosa, limpie la cámara",

              FaceTec_camera_feed_issue_header: "Problema al proteger la conexión de la cámara",
              FaceTec_camera_feed_issue_header_mobile:
                "Problema al proteger<br/>la conexión de la cámara",
              FaceTec_camera_feed_issue_subheader_message_mobile:
                "Esta aplicación bloquea configuraciones de cámara web sospechosas.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; pointer-events:all;'>Aprende más aquí.</a>.",
              FaceTec_camera_feed_issue_subheader_message:
                "Este sistema no se puede verificar debido a lo siguiente:",
              FaceTec_camera_feed_issue_table_header_1: "Posible Problema",
              FaceTec_camera_feed_issue_table_header_2: "Corregir",
              FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error:
                "Permisos de cámara no recordados en Firefox.",
              FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error:
                "Marque Recordar Permisos.",
              FaceTec_camera_feed_issue_table_row_1_cell_1: "Cámara ya en uso por otra aplicación.",
              FaceTec_camera_feed_issue_table_row_1_cell_2: "Cierra la otra aplicación.",
              FaceTec_camera_feed_issue_table_row_2_cell_1:
                "Una aplicación 3rd-Party está modificando el video.",
              FaceTec_camera_feed_issue_table_row_2_cell_2: "Cierre / desinstale la otra aplicación.",
              FaceTec_camera_feed_issue_table_row_3_cell_1: "Equipos que no pueden ser protegidos.",
              FaceTec_camera_feed_issue_table_row_3_cell_2: "Usa un dispositivo diferente.",
              FaceTec_camera_feed_issue_subtable_message:
                "Esta aplicación bloquea configuraciones de cámara web sospechosas. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; pointer-events:all;'>Aprende más aquí.</a>.",
              FaceTec_camera_feed_issue_action: "Volver a intentarlo",
              FaceTec_camera_feed_issue_action_firefox_permissions_error: "Aceptar",

              FaceTec_camera_permission_header: "Habilita tu cámara",
              FaceTec_camera_permission_message:
                "<span>Los permisos de cámara se encuentran deshabilitados. Por favor revisa los ajustes de tu navegador y sistema operativo.<span/>",

              FaceTec_enter_fullscreen_header: "Modo Selfie a Pantalla Completa",
              FaceTec_enter_fullscreen_message:
                "Antes de comenzar, haga clic en el botón de abajo para abrir el modo de pantalla completa.",
              FaceTec_enter_fullscreen_action: "Abrir pantalla completa",

              FaceTec_initializing_camera: "Protegiendo la conexión",

              FaceTec_idscan_type_selection_header:
                "<h2 style=margin-top:24px>Captura anverso del INE<h2/><p style=margin-top:17px>Prepara tu identificación para el escaneo<p/>",
              FaceTec_idscan_capture_id_front_instruction_message: "Anverso de su identificación",
              FaceTec_idscan_capture_id_back_instruction_message: "Reverso de su identificación",
              FaceTec_idscan_review_id_front_instruction_message: "Verifique sea nítida y legible",
              FaceTec_idscan_review_id_back_instruction_message: "Verifique sea nítida y legible",
              FaceTec_idscan_additional_review_message: "Se requiere<br/>una verificación adicional",
              FaceTec_idscan_ocr_confirmation_main_header: "Revisar y confirmar",
              FaceTec_idscan_ocr_confirmation_scroll_message: "Desplácese hacia abajo",
              FaceTec_idscan_feedback_flip_id_to_back_message: "Reverso de su identificación",

              FaceTec_result_success_message: "Listo",
              FaceTec_result_facescan_upload_message:
                "Subiendo<br/>el escaneo facial 3D<br/>encriptado",
              FaceTec_result_idscan_upload_message:
                "Subiendo<br/>el escaneo de identificación<br/>encriptado",
              FaceTec_result_idscan_unsuccess_message:
                "La foto de la identificación<br/>o coincide conel rostro del usuario",
              FaceTec_result_idscan_success_front_side_message:
                "Escaneo de identificación completado",
              FaceTec_result_idscan_success_front_side_back_next_message:
                "Anverso de la identificación<br/>escaneado",
              FaceTec_result_idscan_success_back_side_message: "Escaneo de identificación completado",
              FaceTec_result_idscan_success_passport_message: "Escaneo de pasaporte completado",
              FaceTec_result_idscan_success_user_confirmation_message:
                "Escaneo de identificación con fotografía<br/>completado",
              FaceTec_result_idscan_success_additional_review_message:
                "Captura de la fotografía de identificación<br/>completada",
              FaceTec_result_idscan_retry_face_did_not_match_message:
                "El rostro no coincide<br/>lo suficiente",
              FaceTec_result_idscan_retry_id_not_fully_visible_message:
                "La identificación<br/>no es totalmente visible",
              FaceTec_result_idscan_retry_ocr_results_not_good_enough_message:
                "El texto de la identificación no es legible",
              FaceTec_result_idscan_retry_id_type_not_suppported_message:
                "No se admite este tipo de identificación<br/>Utilice una identificación diferente",
              FaceTec_result_idscan_retry_barcode_not_read_message:
                "No se pudo escanear el código de barras<br/>Inténtelo de nuevo",
            };

            FaceTecSDK.configureLocalization(FaceTecStrings);

            // Set the strings to be used for group names, field names, and placeholder texts for the FaceTec ID Scan User OCR Confirmation Screen.
            SampleAppUtilities.setOCRLocalization();
          }
          SampleAppUtilities.displayStatus(
            FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()),
          );
        },
      );
      SampleAppUtilities.formatUIForDevice();
    }

  };

  enum VocalGuidanceMode {
    MINIMAL,
    FULL,
    OFF,
  }

  const fetchAuthentication = async () => {
    let response;
    let keys;
    try {
      response = await fetch(`${API_GATEWAY}/auth/oauth/token`, {
        method: "POST",
        headers: {
          "Content-Type": 'application/x-www-form-urlencoded',
          "Authorization": `Basic ${APIKEY}`,
          "X-GeoPosition": "MX",
          "X-CallerIP": "1.0.2.3",
        },
        body: 'grant_type=client_credentials&scope=read write',
      });
    } catch (error) {
      console.error(error);
      return;
    }

    try {
      const data = await response.json();
      const key = await fetchKeysFacetec(data.access_token);
      keys = await key.data.licenseText;

    } catch (error) {
      console.error(error)
      return;
    }

    return keys;
  }

  //Get facetec keys
  async function fetchKeysFacetec(token: any) {

    try {
      const response = await fetch(`${API_GATEWAY}/utils-commons/facetec/getInitString/web`, {
        method: "GET",
        headers: {
          "X-GeoPosition": "MX",
          "X-CallerIP": "1.0.2.3",
          Authorization: `Bearer ${token}`,
        }
      });
      return await response.json();
    } catch (error) {
      return;
    }
  }


  // Initiate a 3D Liveness Check. NO ENROLLMENT
  function onLivenessCheckPressed() {
    SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

    // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.
    getSessionToken(function (sessionToken: any) {
      latestProcessor = new LivenessCheckProcessor(sessionToken, SampleApp);
    });
  }

  // Perform Photo ID Scan, generating a username each time to guarantee uniqueness.
  function onPhotoIDScanPressed(): void {
    SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

    // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.  On Success, ID Scanning will start automatically.
    getSessionToken(function (sessionToken?: string) {
      latestProcessor = new PhotoIDScanProcessor(sessionToken as string, SampleApp as any);
    });
  }

  // Clear previous session results;
  function initializeResultObjects(): void {
    latestSessionResult = null;
    latestIDScanResult = null;
  }

  // Initiate a 3D Liveness Check, then storing the 3D FaceMap in the Database, also known as "Enrollment".  A random enrollmentIdentifier is generated each time to guarantee uniqueness.
  function onEnrollUserPressed(): void {
    initializeResultObjects();
    SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

    // Get a Session Token from the FaceTec SDK, then start the Enrollment.
    getSessionToken((sessionToken?: string) => {
      latestEnrollmentIdentifier = Config.ProcessID;
      latestProcessor = new EnrollmentProcessor(sessionToken as string, SampleApp as any);
    });
  }

  // Show the final result and transition back into the main interface.
  function onComplete() {
    SampleAppUtilities.showMainUI();

    if (!latestProcessor.isSuccess()) {
      // Reset the enrollment identifier.
      latestEnrollmentIdentifier = "";

      // Show early exit message to screen.  If this occurs, please check logs.
      SampleAppUtilities.displayStatus("Session exited early, see logs for more details.");

      return;
    }

    // Show successful message to screen
    SampleAppUtilities.displayStatus("Success");
  }

  // Get the Session Token from the server
  function getSessionToken(sessionTokenCallback: any) {
    var XHR = new XMLHttpRequest();
    XHR.open("GET", Config.BaseURL + "/session-token");
    XHR.setRequestHeader("X-Device-Key", Config.DeviceKeyIdentifier);
    XHR.setRequestHeader("process-id", Config.ProcessID);
    XHR.setRequestHeader("Authorization", "Bearer " + Config.AuthToken);
    XHR.setRequestHeader("X-GeoPosition", Config.GeoPosition);
    XHR.setRequestHeader("X-CallerIP", Config.CallerIP);
    XHR.setRequestHeader("X-User-Agent", FaceTecSDK.createFaceTecAPIUserAgentString(""));
    XHR.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE) {
        var sessionToken = "";
        try {
          // Attempt to get the sessionToken from the response object.
          sessionToken = JSON.parse(this.responseText).sessionToken;
          // Something went wrong in parsing the response. Return an error.
          if (typeof sessionToken !== "string") {
            onServerSessionTokenError();
            return;
          }
        } catch {
          // Something went wrong in parsing the response. Return an error.
          onServerSessionTokenError();
          return;
        }
        sessionTokenCallback(sessionToken);
      }
    };

    XHR.onerror = function () {
      onServerSessionTokenError();
    };
    XHR.send();
  }

  function onServerSessionTokenError() {
    SampleAppUtilities.handleErrorGettingServerSessionToken();
  }

  //
  // DEVELOPER NOTE:  This is a convenience function for demonstration purposes only so the Sample App can have access to the latest session results.
  // In your code, you may not even want or need to do this.
  //
  function setLatestSessionResult(sessionResult: any) {
    latestSessionResult = sessionResult;
  }

  function setIDScanResult(idScanResult: any) {
    latestIDScanResult = idScanResult;
  }

  function getLatestEnrollmentIdentifier() {
    return latestEnrollmentIdentifier;
  }

  function setLatestServerResult(responseJSON: any) { }

  function setAppTheme() {
    Config.currentCustomization = getCustomization();
    var currentLowLightCustomization = getLowLightCustomization();
    var currentDynamicDimmingCustomization = getDynamicDimmingCustomization();

    Config.currentCustomization.vocalGuidanceCustomization.mode = VocalGuidanceMode.OFF;

    FaceTecSDK.setCustomization(Config.currentCustomization);
    FaceTecSDK.setLowLightCustomization(currentLowLightCustomization);
    FaceTecSDK.setDynamicDimmingCustomization(currentDynamicDimmingCustomization);
  }

  function getCustomization(): FaceTecCustomization {
    var currentCustomization: FaceTecCustomization = new FaceTecSDK.FaceTecCustomization();

    // Initial Loading Animation Customization
    currentCustomization.initialLoadingAnimationCustomization.customAnimation =
      activityIndicatorSVG;
    currentCustomization.initialLoadingAnimationCustomization.animationRelativeScale = 1.0;
    currentCustomization.initialLoadingAnimationCustomization.backgroundColor = darkBlue;
    currentCustomization.initialLoadingAnimationCustomization.foregroundColor = white;
    currentCustomization.initialLoadingAnimationCustomization.messageTextColor = darkBlue;
    currentCustomization.initialLoadingAnimationCustomization.messageFont = font;

    // Overlay Customization
    currentCustomization.overlayCustomization.backgroundColor = white;
    currentCustomization.overlayCustomization.showBrandingImage = true;
    currentCustomization.overlayCustomization.brandingImage = logoCSB;

    // Guidance Customization - Indiaciones, permisos de cámara, etc.
    currentCustomization.guidanceCustomization.backgroundColors = white;
    currentCustomization.guidanceCustomization.foregroundColor = darkBlue;
    currentCustomization.guidanceCustomization.headerFont = font;
    currentCustomization.guidanceCustomization.subtextFont = font;
    currentCustomization.guidanceCustomization.buttonFont = font;
    currentCustomization.guidanceCustomization.buttonTextNormalColor = white;
    currentCustomization.guidanceCustomization.buttonBackgroundNormalColor = backgroundColorOrange;
    currentCustomization.guidanceCustomization.buttonTextHighlightColor = white;
    currentCustomization.guidanceCustomization.buttonBackgroundHighlightColor =
      backgroundColorOrange;
    currentCustomization.guidanceCustomization.buttonTextDisabledColor = white;
    currentCustomization.guidanceCustomization.buttonBackgroundDisabledColor = disabledColor;
    currentCustomization.guidanceCustomization.buttonBorderColor = "transparent";
    currentCustomization.guidanceCustomization.buttonBorderWidth = "0px";
    currentCustomization.guidanceCustomization.buttonCornerRadius = "8px";
    currentCustomization.guidanceCustomization.readyScreenOvalFillColor = "transparent";
    currentCustomization.guidanceCustomization.readyScreenTextBackgroundColor =
      backgroundColorOrange;
    currentCustomization.guidanceCustomization.readyScreenTextBackgroundCornerRadius = "8px";
    currentCustomization.guidanceCustomization.retryScreenImageBorderColor = borderColor;
    currentCustomization.guidanceCustomization.retryScreenImageBorderWidth = "2px";
    currentCustomization.guidanceCustomization.retryScreenImageCornerRadius = "8px";
    currentCustomization.guidanceCustomization.retryScreenOvalStrokeColor = white;
    //currentCustomization.guidanceCustomization.retryScreenSlideshowImages = retryScreenSlideshowImages;
    currentCustomization.guidanceCustomization.retryScreenSlideshowInterval = "2000ms";
    currentCustomization.guidanceCustomization.enableRetryScreenSlideshowShuffle = true;
    //currentCustomization.guidanceCustomization.cameraPermissionsScreenImage = themeResourceDirectory + "pseudo-fullscreen/camera_shutter_black.png";

    // ID Scan Customization
    currentCustomization.idScanCustomization.showSelectionScreenDocumentImage = true;
    //currentCustomization.idScanCustomization.selectionScreenDocumentImage = themeResourceDirectory + "pseudo-fullscreen/document_offblack.png";
    currentCustomization.idScanCustomization.showSelectionScreenBrandingImage = false;
    currentCustomization.idScanCustomization.selectionScreenBrandingImage = "";
    currentCustomization.idScanCustomization.selectionScreenBackgroundColors = white;
    currentCustomization.idScanCustomization.reviewScreenBackgroundColors = white;
    currentCustomization.idScanCustomization.captureScreenForegroundColor = darkBlue;
    currentCustomization.idScanCustomization.reviewScreenForegroundColor = darkBlue;
    currentCustomization.idScanCustomization.selectionScreenForegroundColor = darkBlue;
    currentCustomization.idScanCustomization.headerFont = font;
    currentCustomization.idScanCustomization.subtextFont = font;
    currentCustomization.idScanCustomization.buttonFont = font;
    currentCustomization.idScanCustomization.buttonTextNormalColor = white;
    currentCustomization.idScanCustomization.buttonBackgroundNormalColor = backgroundColorOrange;
    currentCustomization.idScanCustomization.buttonTextHighlightColor = white;
    currentCustomization.idScanCustomization.buttonBackgroundHighlightColor = backgroundColorOrange;
    currentCustomization.idScanCustomization.buttonTextDisabledColor = white;
    currentCustomization.idScanCustomization.buttonBackgroundDisabledColor = disabledColor;
    currentCustomization.idScanCustomization.buttonBorderColor = "transparent";
    currentCustomization.idScanCustomization.buttonBorderWidth = "0px";
    currentCustomization.idScanCustomization.buttonCornerRadius = "8px";
    currentCustomization.idScanCustomization.captureScreenTextBackgroundColor = darkBlue;
    currentCustomization.idScanCustomization.captureScreenTextBackgroundBorderColor = darkBlue;
    currentCustomization.idScanCustomization.captureScreenTextBackgroundBorderWidth = "2px";
    currentCustomization.idScanCustomization.captureScreenTextBackgroundCornerRadius = "8px";
    currentCustomization.idScanCustomization.reviewScreenTextBackgroundColor = white;
    currentCustomization.idScanCustomization.reviewScreenTextBackgroundBorderColor = darkBlue;
    currentCustomization.idScanCustomization.reviewScreenTextBackgroundBorderWidth = "2px";
    currentCustomization.idScanCustomization.reviewScreenTextBackgroundBorderCornerRadius = "8px";
    currentCustomization.idScanCustomization.captureScreenBackgroundColor = white;
    currentCustomization.idScanCustomization.captureFrameStrokeColor = white;
    currentCustomization.idScanCustomization.captureFrameStrokeWidth = "2px";
    currentCustomization.idScanCustomization.captureFrameCornerRadius = "8px";

    // OCR Confirmation Screen Customization
    currentCustomization.ocrConfirmationCustomization.backgroundColors = white;
    currentCustomization.ocrConfirmationCustomization.mainHeaderDividerLineColor = secondaryColor;
    currentCustomization.ocrConfirmationCustomization.mainHeaderDividerLineWidth = "2px";
    currentCustomization.ocrConfirmationCustomization.mainHeaderFont = font;
    currentCustomization.ocrConfirmationCustomization.sectionHeaderFont = font;
    currentCustomization.ocrConfirmationCustomization.fieldLabelFont = font;
    currentCustomization.ocrConfirmationCustomization.fieldValueFont = font;
    currentCustomization.ocrConfirmationCustomization.inputFieldFont = font;
    currentCustomization.ocrConfirmationCustomization.inputFieldPlaceholderFont = font;
    currentCustomization.ocrConfirmationCustomization.mainHeaderTextColor = secondaryColor;
    currentCustomization.ocrConfirmationCustomization.sectionHeaderTextColor = darkBlue;
    currentCustomization.ocrConfirmationCustomization.fieldLabelTextColor = darkBlue;
    currentCustomization.ocrConfirmationCustomization.fieldValueTextColor = darkBlue;
    currentCustomization.ocrConfirmationCustomization.inputFieldTextColor = darkBlue;
    currentCustomization.ocrConfirmationCustomization.inputFieldPlaceholderTextColor =
      secondaryColor;
    currentCustomization.ocrConfirmationCustomization.inputFieldBackgroundColor = "transparent";
    currentCustomization.ocrConfirmationCustomization.inputFieldBorderColor = secondaryColor;
    currentCustomization.ocrConfirmationCustomization.inputFieldBorderWidth = "2px";
    currentCustomization.ocrConfirmationCustomization.inputFieldCornerRadius = "0px";
    currentCustomization.ocrConfirmationCustomization.showInputFieldBottomBorderOnly = true;
    currentCustomization.ocrConfirmationCustomization.buttonFont = font;
    currentCustomization.ocrConfirmationCustomization.buttonTextNormalColor = white;
    currentCustomization.ocrConfirmationCustomization.buttonBackgroundNormalColor =
      backgroundColorOrange;
    currentCustomization.ocrConfirmationCustomization.buttonTextHighlightColor = white;
    currentCustomization.ocrConfirmationCustomization.buttonBackgroundHighlightColor =
      backgroundColorOrange;
    currentCustomization.ocrConfirmationCustomization.buttonTextDisabledColor = white;
    currentCustomization.ocrConfirmationCustomization.buttonBackgroundDisabledColor = disabledColor;
    currentCustomization.ocrConfirmationCustomization.buttonBorderColor = "transparent";
    currentCustomization.ocrConfirmationCustomization.buttonBorderWidth = "0px";
    currentCustomization.ocrConfirmationCustomization.buttonCornerRadius = "8px";

    // Result Screen Customization
    currentCustomization.resultScreenCustomization.backgroundColors = white;
    currentCustomization.resultScreenCustomization.foregroundColor = darkBlue;
    currentCustomization.resultScreenCustomization.messageFont = font;
    currentCustomization.resultScreenCustomization.activityIndicatorColor = darkBlue;
    //currentCustomization.resultScreenCustomization.customActivityIndicatorImage = themeResourceDirectory + "pseudo-fullscreen/activity_indicator_faded_black.png";
    currentCustomization.resultScreenCustomization.customActivityIndicatorRotationInterval = "0.8s";
    currentCustomization.resultScreenCustomization.customActivityIndicatorAnimation =
      activityIndicatorSVG;
    currentCustomization.resultScreenCustomization.resultAnimationBackgroundColor = darkBlue;
    currentCustomization.resultScreenCustomization.resultAnimationForegroundColor = white;
    currentCustomization.resultScreenCustomization.resultAnimationSuccessBackgroundImage = "";
    currentCustomization.resultScreenCustomization.resultAnimationUnsuccessBackgroundImage = "";
    currentCustomization.resultScreenCustomization.customResultAnimationSuccess =
      successResultAnimationSVG;
    currentCustomization.resultScreenCustomization.customResultAnimationUnsuccess =
      unsuccessResultAnimationSVG;
    currentCustomization.resultScreenCustomization.showUploadProgressBar = true;
    currentCustomization.resultScreenCustomization.uploadProgressTrackColor = "rgba(0, 0, 0, 0.2)";
    currentCustomization.resultScreenCustomization.uploadProgressFillColor = darkBlue;
    currentCustomization.resultScreenCustomization.animationRelativeScale = 1.0;

    // Feedback Customization
    currentCustomization.feedbackCustomization.backgroundColor = darkBlue;
    currentCustomization.feedbackCustomization.textColor = white;
    currentCustomization.feedbackCustomization.textFont = font;
    currentCustomization.feedbackCustomization.cornerRadius = "8px";
    currentCustomization.feedbackCustomization.shadow = "0px 3px 10px black";

    // Frame Customization
    currentCustomization.frameCustomization.backgroundColor = white;
    currentCustomization.frameCustomization.borderColor = borderColor;
    currentCustomization.frameCustomization.borderWidth = "2px";
    currentCustomization.frameCustomization.borderCornerRadius = "16px";
    currentCustomization.frameCustomization.shadow = "none";

    // Oval Customization
    currentCustomization.ovalCustomization.strokeColor = darkBlue;
    currentCustomization.ovalCustomization.progressColor1 = darkBlue;
    currentCustomization.ovalCustomization.progressColor2 = darkBlue;

    // Cancel Button Customization
    currentCustomization.cancelButtonCustomization.customImage =
      imagesDirectory + "FaceTec_cancel.png";
    currentCustomization.cancelButtonCustomization.location =
      FaceTecSDK.FaceTecCancelButtonLocation.TopLeft;
    // currentCustomization.cancelButtonCustomization.setCustomLocation(50, 5, 24, 24);

    // Guidance Customization -- Text Style Overrides
    // Ready Screen Header
    currentCustomization.guidanceCustomization.readyScreenHeaderFont = font;
    currentCustomization.guidanceCustomization.readyScreenHeaderTextColor = darkBlue;
    // Ready Screen Subtext
    currentCustomization.guidanceCustomization.readyScreenSubtextFont = font;
    currentCustomization.guidanceCustomization.readyScreenSubtextTextColor = "#565656";
    // Retry Screen Header
    currentCustomization.guidanceCustomization.retryScreenHeaderFont = font;
    currentCustomization.guidanceCustomization.retryScreenHeaderTextColor = darkBlue;
    // Retry Screen Subtext
    currentCustomization.guidanceCustomization.retryScreenSubtextFont = font;
    currentCustomization.guidanceCustomization.retryScreenSubtextTextColor = "#565656";
    // Security Watermark Customization
    currentCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(
      FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec,
    );
    return currentCustomization;
  }

  function getLowLightCustomization(): FaceTecCustomization | null {
    var currentLowLightCustomization: FaceTecCustomization = getCustomization();

    // Initial Loading Animation Customization
    currentLowLightCustomization.initialLoadingAnimationCustomization.customAnimation =
      activityIndicatorSVG;
    currentLowLightCustomization.initialLoadingAnimationCustomization.animationRelativeScale = 1.0;
    currentLowLightCustomization.initialLoadingAnimationCustomization.backgroundColor = darkBlue;
    currentLowLightCustomization.initialLoadingAnimationCustomization.foregroundColor = white;
    currentLowLightCustomization.initialLoadingAnimationCustomization.messageTextColor = darkBlue;
    currentLowLightCustomization.initialLoadingAnimationCustomization.messageFont = font;

    // Overlay Customization
    currentLowLightCustomization.overlayCustomization.backgroundColor = white;
    currentLowLightCustomization.overlayCustomization.showBrandingImage = true;
    currentLowLightCustomization.overlayCustomization.brandingImage = logoCSB;

    // Guidance Customization
    currentLowLightCustomization.guidanceCustomization.backgroundColors = white;
    currentLowLightCustomization.guidanceCustomization.foregroundColor = darkBlue;
    currentLowLightCustomization.guidanceCustomization.headerFont = font;
    currentLowLightCustomization.guidanceCustomization.subtextFont = font;
    currentLowLightCustomization.guidanceCustomization.buttonFont = font;
    currentLowLightCustomization.guidanceCustomization.buttonTextNormalColor = white;
    currentLowLightCustomization.guidanceCustomization.buttonBackgroundNormalColor =
      backgroundColorOrange;
    currentLowLightCustomization.guidanceCustomization.buttonTextHighlightColor = white;
    currentLowLightCustomization.guidanceCustomization.buttonBackgroundHighlightColor =
      backgroundColorOrange;
    currentLowLightCustomization.guidanceCustomization.buttonTextDisabledColor = white;
    currentLowLightCustomization.guidanceCustomization.buttonBackgroundDisabledColor =
      disabledColor;
    currentLowLightCustomization.guidanceCustomization.buttonBorderColor = "transparent";
    currentLowLightCustomization.guidanceCustomization.buttonBorderWidth = "0px";
    currentLowLightCustomization.guidanceCustomization.buttonCornerRadius = "8px";
    currentLowLightCustomization.guidanceCustomization.readyScreenOvalFillColor = "transparent";
    currentLowLightCustomization.guidanceCustomization.readyScreenTextBackgroundColor =
      backgroundColorOrange;
    currentLowLightCustomization.guidanceCustomization.readyScreenTextBackgroundCornerRadius =
      "8px";
    currentLowLightCustomization.guidanceCustomization.retryScreenImageBorderColor = borderColor;
    currentLowLightCustomization.guidanceCustomization.retryScreenImageBorderWidth = "2px";
    currentLowLightCustomization.guidanceCustomization.retryScreenImageCornerRadius = "8px";
    currentLowLightCustomization.guidanceCustomization.retryScreenOvalStrokeColor = white;
    //currentLowLightCustomization.guidanceCustomization.retryScreenSlideshowImages = retryScreenSlideshowImages;
    currentLowLightCustomization.guidanceCustomization.retryScreenSlideshowInterval = "2000ms";
    currentLowLightCustomization.guidanceCustomization.enableRetryScreenSlideshowShuffle = true;
    //currentLowLightCustomization.guidanceCustomization.cameraPermissionsScreenImage = themeResourceDirectory + "pseudo-fullscreen/camera_shutter_black.png";

    // ID Scan Customization
    currentLowLightCustomization.idScanCustomization.showSelectionScreenDocumentImage = true;
    //currentLowLightCustomization.idScanCustomization.selectionScreenDocumentImage = themeResourceDirectory + "pseudo-fullscreen/document_offblack.png";
    currentLowLightCustomization.idScanCustomization.showSelectionScreenBrandingImage = false;
    currentLowLightCustomization.idScanCustomization.selectionScreenBrandingImage = "";
    currentLowLightCustomization.idScanCustomization.selectionScreenBackgroundColors = white;
    currentLowLightCustomization.idScanCustomization.reviewScreenBackgroundColors = white;
    currentLowLightCustomization.idScanCustomization.captureScreenForegroundColor = darkBlue;
    currentLowLightCustomization.idScanCustomization.reviewScreenForegroundColor = darkBlue;
    currentLowLightCustomization.idScanCustomization.selectionScreenForegroundColor = darkBlue;
    currentLowLightCustomization.idScanCustomization.headerFont = font;
    currentLowLightCustomization.idScanCustomization.subtextFont = font;
    currentLowLightCustomization.idScanCustomization.buttonFont = font;
    currentLowLightCustomization.idScanCustomization.buttonTextNormalColor = white;
    currentLowLightCustomization.idScanCustomization.buttonBackgroundNormalColor =
      backgroundColorOrange;
    currentLowLightCustomization.idScanCustomization.buttonTextHighlightColor = white;
    currentLowLightCustomization.idScanCustomization.buttonBackgroundHighlightColor =
      backgroundColorOrange;
    currentLowLightCustomization.idScanCustomization.buttonTextDisabledColor = white;
    currentLowLightCustomization.idScanCustomization.buttonBackgroundDisabledColor = disabledColor;
    currentLowLightCustomization.idScanCustomization.buttonBorderColor = "transparent";
    currentLowLightCustomization.idScanCustomization.buttonBorderWidth = "0px";
    currentLowLightCustomization.idScanCustomization.buttonCornerRadius = "8px";
    currentLowLightCustomization.idScanCustomization.captureScreenTextBackgroundColor = darkBlue;
    currentLowLightCustomization.idScanCustomization.captureScreenTextBackgroundBorderColor =
      darkBlue;
    currentLowLightCustomization.idScanCustomization.captureScreenTextBackgroundBorderWidth = "2px";
    currentLowLightCustomization.idScanCustomization.captureScreenTextBackgroundCornerRadius =
      "8px";
    currentLowLightCustomization.idScanCustomization.reviewScreenTextBackgroundColor = white;
    currentLowLightCustomization.idScanCustomization.reviewScreenTextBackgroundBorderColor =
      darkBlue;
    currentLowLightCustomization.idScanCustomization.reviewScreenTextBackgroundBorderWidth = "2px";
    currentLowLightCustomization.idScanCustomization.reviewScreenTextBackgroundBorderCornerRadius =
      "8px";
    currentLowLightCustomization.idScanCustomization.captureScreenBackgroundColor = white;
    currentLowLightCustomization.idScanCustomization.captureFrameStrokeColor = white;
    currentLowLightCustomization.idScanCustomization.captureFrameStrokeWidth = "2px";
    currentLowLightCustomization.idScanCustomization.captureFrameCornerRadius = "8px";
    // OCR Confirmation Screen Customization
    currentLowLightCustomization.ocrConfirmationCustomization.backgroundColors = white;
    currentLowLightCustomization.ocrConfirmationCustomization.mainHeaderDividerLineColor =
      secondaryColor;
    currentLowLightCustomization.ocrConfirmationCustomization.mainHeaderDividerLineWidth = "2px";
    currentLowLightCustomization.ocrConfirmationCustomization.mainHeaderFont = font;
    currentLowLightCustomization.ocrConfirmationCustomization.sectionHeaderFont = font;
    currentLowLightCustomization.ocrConfirmationCustomization.fieldLabelFont = font;
    currentLowLightCustomization.ocrConfirmationCustomization.fieldValueFont = font;
    currentLowLightCustomization.ocrConfirmationCustomization.inputFieldFont = font;
    currentLowLightCustomization.ocrConfirmationCustomization.inputFieldPlaceholderFont = font;
    currentLowLightCustomization.ocrConfirmationCustomization.mainHeaderTextColor = secondaryColor;
    currentLowLightCustomization.ocrConfirmationCustomization.sectionHeaderTextColor = darkBlue;
    currentLowLightCustomization.ocrConfirmationCustomization.fieldLabelTextColor = darkBlue;
    currentLowLightCustomization.ocrConfirmationCustomization.fieldValueTextColor = darkBlue;
    currentLowLightCustomization.ocrConfirmationCustomization.inputFieldTextColor = darkBlue;
    currentLowLightCustomization.ocrConfirmationCustomization.inputFieldPlaceholderTextColor =
      secondaryColor;
    currentLowLightCustomization.ocrConfirmationCustomization.inputFieldBackgroundColor =
      "transparent";
    currentLowLightCustomization.ocrConfirmationCustomization.inputFieldBorderColor =
      secondaryColor;
    currentLowLightCustomization.ocrConfirmationCustomization.inputFieldBorderWidth = "2px";
    currentLowLightCustomization.ocrConfirmationCustomization.inputFieldCornerRadius = "0px";
    currentLowLightCustomization.ocrConfirmationCustomization.showInputFieldBottomBorderOnly = true;
    currentLowLightCustomization.ocrConfirmationCustomization.buttonFont = font;
    currentLowLightCustomization.ocrConfirmationCustomization.buttonTextNormalColor = white;
    currentLowLightCustomization.ocrConfirmationCustomization.buttonBackgroundNormalColor =
      backgroundColorOrange;
    currentLowLightCustomization.ocrConfirmationCustomization.buttonTextHighlightColor = white;
    currentLowLightCustomization.ocrConfirmationCustomization.buttonBackgroundHighlightColor =
      backgroundColorOrange;
    currentLowLightCustomization.ocrConfirmationCustomization.buttonTextDisabledColor = white;
    currentLowLightCustomization.ocrConfirmationCustomization.buttonBackgroundDisabledColor =
      disabledColor;
    currentLowLightCustomization.ocrConfirmationCustomization.buttonBorderColor = "transparent";
    currentLowLightCustomization.ocrConfirmationCustomization.buttonBorderWidth = "0px";
    currentLowLightCustomization.ocrConfirmationCustomization.buttonCornerRadius = "8px";
    // Result Screen Customization
    currentLowLightCustomization.resultScreenCustomization.backgroundColors = white;
    currentLowLightCustomization.resultScreenCustomization.foregroundColor = darkBlue;
    currentLowLightCustomization.resultScreenCustomization.messageFont = font;
    currentLowLightCustomization.resultScreenCustomization.activityIndicatorColor = darkBlue;
    //currentLowLightCustomization.resultScreenCustomization.customActivityIndicatorImage = themeResourceDirectory + "pseudo-fullscreen/activity_indicator_faded_black.png";
    currentLowLightCustomization.resultScreenCustomization.customActivityIndicatorRotationInterval =
      "0.8s";
    currentLowLightCustomization.resultScreenCustomization.customActivityIndicatorAnimation =
      activityIndicatorSVG;
    currentLowLightCustomization.resultScreenCustomization.resultAnimationBackgroundColor =
      darkBlue;
    currentLowLightCustomization.resultScreenCustomization.resultAnimationForegroundColor = white;
    currentLowLightCustomization.resultScreenCustomization.resultAnimationSuccessBackgroundImage =
      "";
    currentLowLightCustomization.resultScreenCustomization.resultAnimationUnsuccessBackgroundImage =
      "";
    currentLowLightCustomization.resultScreenCustomization.customResultAnimationSuccess =
      successResultAnimationSVG;
    currentLowLightCustomization.resultScreenCustomization.customResultAnimationUnsuccess =
      unsuccessResultAnimationSVG;
    currentLowLightCustomization.resultScreenCustomization.showUploadProgressBar = true;
    currentLowLightCustomization.resultScreenCustomization.uploadProgressTrackColor =
      "rgba(0, 0, 0, 0.2)";
    currentLowLightCustomization.resultScreenCustomization.uploadProgressFillColor = darkBlue;
    currentLowLightCustomization.resultScreenCustomization.animationRelativeScale = 1.0;
    // Feedback Customization
    currentLowLightCustomization.feedbackCustomization.backgroundColor = darkBlue;
    currentLowLightCustomization.feedbackCustomization.textColor = white;
    currentLowLightCustomization.feedbackCustomization.textFont = font;
    currentLowLightCustomization.feedbackCustomization.cornerRadius = "8px";
    currentLowLightCustomization.feedbackCustomization.shadow = "0px 3px 10px black";
    // Frame Customization
    currentLowLightCustomization.frameCustomization.backgroundColor = darkBlue;
    currentLowLightCustomization.frameCustomization.borderColor = borderColor;
    currentLowLightCustomization.frameCustomization.borderWidth = "2px";
    currentLowLightCustomization.frameCustomization.borderCornerRadius = "16px";
    currentLowLightCustomization.frameCustomization.shadow = "none";
    // Oval Customization
    currentLowLightCustomization.ovalCustomization.strokeColor = darkBlue;
    currentLowLightCustomization.ovalCustomization.progressColor1 = darkBlue;
    currentLowLightCustomization.ovalCustomization.progressColor2 = darkBlue;
    // Cancel Button Customization
    currentLowLightCustomization.cancelButtonCustomization.customImage =
      imagesDirectory + "FaceTec_cancel.png";
    currentLowLightCustomization.cancelButtonCustomization.location =
      FaceTecSDK.FaceTecCancelButtonLocation.TopLeft;
    // currentLowLightCustomization.cancelButtonCustomization.setCustomLocation(50, 5, 24, 24);

    // Guidance Customization -- Text Style Overrides
    // Ready Screen Header
    currentLowLightCustomization.guidanceCustomization.readyScreenHeaderFont = font;
    currentLowLightCustomization.guidanceCustomization.readyScreenHeaderTextColor = darkBlue;
    // Ready Screen Subtext
    currentLowLightCustomization.guidanceCustomization.readyScreenSubtextFont = font;
    currentLowLightCustomization.guidanceCustomization.readyScreenSubtextTextColor = "#565656";
    // Retry Screen Header
    currentLowLightCustomization.guidanceCustomization.retryScreenHeaderFont = font;
    currentLowLightCustomization.guidanceCustomization.retryScreenHeaderTextColor = darkBlue;
    // Retry Screen Subtext
    currentLowLightCustomization.guidanceCustomization.retryScreenSubtextFont = font;
    currentLowLightCustomization.guidanceCustomization.retryScreenSubtextTextColor = "#565656";
    // Security Watermark Customization
    currentLowLightCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(
      FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec,
    );
    return currentLowLightCustomization;
  }

  function getDynamicDimmingCustomization(): FaceTecCustomization | null {
    var currentDynamicDimmingCustomization: FaceTecCustomization = getCustomization();

    // Initial Loading Animation Customization
    currentDynamicDimmingCustomization.initialLoadingAnimationCustomization.customAnimation =
      activityIndicatorSVG;
    currentDynamicDimmingCustomization.initialLoadingAnimationCustomization.animationRelativeScale = 1.0;
    currentDynamicDimmingCustomization.initialLoadingAnimationCustomization.backgroundColor =
      darkBlue;
    currentDynamicDimmingCustomization.initialLoadingAnimationCustomization.foregroundColor = white;
    currentDynamicDimmingCustomization.initialLoadingAnimationCustomization.messageTextColor =
      darkBlue;
    currentDynamicDimmingCustomization.initialLoadingAnimationCustomization.messageFont = font;
    // Overlay Customization
    currentDynamicDimmingCustomization.overlayCustomization.backgroundColor = white;
    currentDynamicDimmingCustomization.overlayCustomization.showBrandingImage = true;
    currentDynamicDimmingCustomization.overlayCustomization.brandingImage = logoCSB;
    // Guidance Customization
    currentDynamicDimmingCustomization.guidanceCustomization.backgroundColors = white;
    currentDynamicDimmingCustomization.guidanceCustomization.foregroundColor = darkBlue;
    currentDynamicDimmingCustomization.guidanceCustomization.headerFont = font;
    currentDynamicDimmingCustomization.guidanceCustomization.subtextFont = font;
    currentDynamicDimmingCustomization.guidanceCustomization.buttonFont = font;
    currentDynamicDimmingCustomization.guidanceCustomization.buttonTextNormalColor = white;
    currentDynamicDimmingCustomization.guidanceCustomization.buttonBackgroundNormalColor =
      backgroundColorOrange;
    currentDynamicDimmingCustomization.guidanceCustomization.buttonTextHighlightColor = white;
    currentDynamicDimmingCustomization.guidanceCustomization.buttonBackgroundHighlightColor =
      backgroundColorOrange;
    currentDynamicDimmingCustomization.guidanceCustomization.buttonTextDisabledColor = white;
    currentDynamicDimmingCustomization.guidanceCustomization.buttonBackgroundDisabledColor =
      disabledColor;
    currentDynamicDimmingCustomization.guidanceCustomization.buttonBorderColor = "transparent";
    currentDynamicDimmingCustomization.guidanceCustomization.buttonBorderWidth = "0px";
    currentDynamicDimmingCustomization.guidanceCustomization.buttonCornerRadius = "8px";
    currentDynamicDimmingCustomization.guidanceCustomization.readyScreenOvalFillColor =
      "transparent";
    currentDynamicDimmingCustomization.guidanceCustomization.readyScreenTextBackgroundColor =
      backgroundColorOrange;
    currentDynamicDimmingCustomization.guidanceCustomization.readyScreenTextBackgroundCornerRadius =
      "8px";
    currentDynamicDimmingCustomization.guidanceCustomization.retryScreenImageBorderColor =
      borderColor;
    currentDynamicDimmingCustomization.guidanceCustomization.retryScreenImageBorderWidth = "2px";
    currentDynamicDimmingCustomization.guidanceCustomization.retryScreenImageCornerRadius = "8px";
    currentDynamicDimmingCustomization.guidanceCustomization.retryScreenOvalStrokeColor = white;
    //currentDynamicDimmingCustomization.guidanceCustomization.retryScreenSlideshowImages = retryScreenSlideshowImages;
    currentDynamicDimmingCustomization.guidanceCustomization.retryScreenSlideshowInterval =
      "2000ms";
    currentDynamicDimmingCustomization.guidanceCustomization.enableRetryScreenSlideshowShuffle =
      true;
    //currentDynamicDimmingCustomization.guidanceCustomization.cameraPermissionsScreenImage = themeResourceDirectory + "pseudo-fullscreen/camera_shutter_black.png";
    // ID Scan Customization
    currentDynamicDimmingCustomization.idScanCustomization.showSelectionScreenDocumentImage = true;
    //currentDynamicDimmingCustomization.idScanCustomization.selectionScreenDocumentImage = themeResourceDirectory + "pseudo-fullscreen/document_offblack.png";
    currentDynamicDimmingCustomization.idScanCustomization.showSelectionScreenBrandingImage = false;
    currentDynamicDimmingCustomization.idScanCustomization.selectionScreenBrandingImage = "";
    currentDynamicDimmingCustomization.idScanCustomization.selectionScreenBackgroundColors = white;
    currentDynamicDimmingCustomization.idScanCustomization.reviewScreenBackgroundColors = white;
    currentDynamicDimmingCustomization.idScanCustomization.captureScreenForegroundColor = darkBlue;
    currentDynamicDimmingCustomization.idScanCustomization.reviewScreenForegroundColor = darkBlue;
    currentDynamicDimmingCustomization.idScanCustomization.selectionScreenForegroundColor =
      darkBlue;
    currentDynamicDimmingCustomization.idScanCustomization.headerFont = font;
    currentDynamicDimmingCustomization.idScanCustomization.subtextFont = font;
    currentDynamicDimmingCustomization.idScanCustomization.buttonFont = font;
    currentDynamicDimmingCustomization.idScanCustomization.buttonTextNormalColor = white;
    currentDynamicDimmingCustomization.idScanCustomization.buttonBackgroundNormalColor =
      backgroundColorOrange;
    currentDynamicDimmingCustomization.idScanCustomization.buttonTextHighlightColor = white;
    currentDynamicDimmingCustomization.idScanCustomization.buttonBackgroundHighlightColor =
      backgroundColorOrange;
    currentDynamicDimmingCustomization.idScanCustomization.buttonTextDisabledColor = white;
    currentDynamicDimmingCustomization.idScanCustomization.buttonBackgroundDisabledColor =
      disabledColor;
    currentDynamicDimmingCustomization.idScanCustomization.buttonBorderColor = "transparent";
    currentDynamicDimmingCustomization.idScanCustomization.buttonBorderWidth = "0px";
    currentDynamicDimmingCustomization.idScanCustomization.buttonCornerRadius = "8px";
    currentDynamicDimmingCustomization.idScanCustomization.captureScreenTextBackgroundColor =
      darkBlue;
    currentDynamicDimmingCustomization.idScanCustomization.captureScreenTextBackgroundBorderColor =
      darkBlue;
    currentDynamicDimmingCustomization.idScanCustomization.captureScreenTextBackgroundBorderWidth =
      "2px";
    currentDynamicDimmingCustomization.idScanCustomization.captureScreenTextBackgroundCornerRadius =
      "8px";
    currentDynamicDimmingCustomization.idScanCustomization.reviewScreenTextBackgroundColor = white;
    currentDynamicDimmingCustomization.idScanCustomization.reviewScreenTextBackgroundBorderColor =
      darkBlue;
    currentDynamicDimmingCustomization.idScanCustomization.reviewScreenTextBackgroundBorderWidth =
      "2px";
    currentDynamicDimmingCustomization.idScanCustomization.reviewScreenTextBackgroundBorderCornerRadius =
      "8px";
    currentDynamicDimmingCustomization.idScanCustomization.captureScreenBackgroundColor = white;
    currentDynamicDimmingCustomization.idScanCustomization.captureFrameStrokeColor = white;
    currentDynamicDimmingCustomization.idScanCustomization.captureFrameStrokeWidth = "2px";
    currentDynamicDimmingCustomization.idScanCustomization.captureFrameCornerRadius = "8px";
    // OCR Confirmation Screen Customization
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.backgroundColors = white;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.mainHeaderDividerLineColor =
      secondaryColor;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.mainHeaderDividerLineWidth =
      "2px";
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.mainHeaderFont = font;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.sectionHeaderFont = font;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.fieldLabelFont = font;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.fieldValueFont = font;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.inputFieldFont = font;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.inputFieldPlaceholderFont =
      font;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.mainHeaderTextColor =
      secondaryColor;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.sectionHeaderTextColor =
      darkBlue;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.fieldLabelTextColor = darkBlue;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.fieldValueTextColor = darkBlue;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.inputFieldTextColor = darkBlue;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.inputFieldPlaceholderTextColor =
      secondaryColor;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.inputFieldBackgroundColor =
      "transparent";
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.inputFieldBorderColor =
      secondaryColor;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.inputFieldBorderWidth = "2px";
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.inputFieldCornerRadius = "0px";
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.showInputFieldBottomBorderOnly =
      true;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.buttonFont = font;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.buttonTextNormalColor = white;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.buttonBackgroundNormalColor =
      backgroundColorOrange;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.buttonTextHighlightColor =
      white;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.buttonBackgroundHighlightColor =
      backgroundColorOrange;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.buttonTextDisabledColor = white;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.buttonBackgroundDisabledColor =
      disabledColor;
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.buttonBorderColor =
      "transparent";
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.buttonBorderWidth = "0px";
    currentDynamicDimmingCustomization.ocrConfirmationCustomization.buttonCornerRadius = "8px";
    // Result Screen Customization
    currentDynamicDimmingCustomization.resultScreenCustomization.backgroundColors = white;
    currentDynamicDimmingCustomization.resultScreenCustomization.foregroundColor = darkBlue;
    currentDynamicDimmingCustomization.resultScreenCustomization.messageFont = font;
    currentDynamicDimmingCustomization.resultScreenCustomization.activityIndicatorColor = darkBlue;
    //currentDynamicDimmingCustomization.resultScreenCustomization.customActivityIndicatorImage = themeResourceDirectory + "pseudo-fullscreen/activity_indicator_faded_black.png";
    currentDynamicDimmingCustomization.resultScreenCustomization.customActivityIndicatorRotationInterval =
      "0.8s";
    currentDynamicDimmingCustomization.resultScreenCustomization.customActivityIndicatorAnimation =
      activityIndicatorSVG;
    currentDynamicDimmingCustomization.resultScreenCustomization.resultAnimationBackgroundColor =
      darkBlue;
    currentDynamicDimmingCustomization.resultScreenCustomization.resultAnimationForegroundColor =
      white;
    currentDynamicDimmingCustomization.resultScreenCustomization.resultAnimationSuccessBackgroundImage =
      "";
    currentDynamicDimmingCustomization.resultScreenCustomization.resultAnimationUnsuccessBackgroundImage =
      "";
    currentDynamicDimmingCustomization.resultScreenCustomization.customResultAnimationSuccess =
      successResultAnimationSVG;
    currentDynamicDimmingCustomization.resultScreenCustomization.customResultAnimationUnsuccess =
      unsuccessResultAnimationSVG;
    currentDynamicDimmingCustomization.resultScreenCustomization.showUploadProgressBar = true;
    currentDynamicDimmingCustomization.resultScreenCustomization.uploadProgressTrackColor =
      "rgba(0, 0, 0, 0.2)";
    currentDynamicDimmingCustomization.resultScreenCustomization.uploadProgressFillColor = darkBlue;
    currentDynamicDimmingCustomization.resultScreenCustomization.animationRelativeScale = 1.0;
    // Feedback Customization
    currentDynamicDimmingCustomization.feedbackCustomization.backgroundColor = secondaryColor;
    currentDynamicDimmingCustomization.feedbackCustomization.textColor = white;
    currentDynamicDimmingCustomization.feedbackCustomization.textFont = font;
    currentDynamicDimmingCustomization.feedbackCustomization.cornerRadius = "8px";
    currentDynamicDimmingCustomization.feedbackCustomization.shadow = "0px 3px 10px black";
    // Frame Customization
    currentDynamicDimmingCustomization.frameCustomization.backgroundColor = darkBlue;
    currentDynamicDimmingCustomization.frameCustomization.borderColor = borderColor;
    currentDynamicDimmingCustomization.frameCustomization.borderWidth = "2px";
    currentDynamicDimmingCustomization.frameCustomization.borderCornerRadius = "16px";
    currentDynamicDimmingCustomization.frameCustomization.shadow = "none";
    // Oval Customization
    currentDynamicDimmingCustomization.ovalCustomization.strokeColor = darkBlue;
    currentDynamicDimmingCustomization.ovalCustomization.progressColor1 = darkBlue;
    currentDynamicDimmingCustomization.ovalCustomization.progressColor2 = darkBlue;

    // Cancel Button Customization
    currentDynamicDimmingCustomization.cancelButtonCustomization.customImage =
      imagesDirectory + "FaceTec_cancel.png";
    currentDynamicDimmingCustomization.cancelButtonCustomization.location =
      FaceTecSDK.FaceTecCancelButtonLocation.TopLeft;
    // currentDynamicDimmingCustomization.cancelButtonCustomization.setCustomLocation(50, 5, 24, 24);

    // Guidance Customization -- Text Style Overrides
    // Ready Screen Header
    currentDynamicDimmingCustomization.guidanceCustomization.readyScreenHeaderFont = font;
    currentDynamicDimmingCustomization.guidanceCustomization.readyScreenHeaderTextColor = darkBlue;
    // Ready Screen Subtext
    currentDynamicDimmingCustomization.guidanceCustomization.readyScreenSubtextFont = font;
    currentDynamicDimmingCustomization.guidanceCustomization.readyScreenSubtextTextColor =
      "#565656";
    // Retry Screen Header
    currentDynamicDimmingCustomization.guidanceCustomization.retryScreenHeaderFont = font;
    currentDynamicDimmingCustomization.guidanceCustomization.retryScreenHeaderTextColor = darkBlue;
    // Retry Screen Subtext
    currentDynamicDimmingCustomization.guidanceCustomization.retryScreenSubtextFont = font;
    currentDynamicDimmingCustomization.guidanceCustomization.retryScreenSubtextTextColor =
      "#565656";
    // Security Watermark Customization
    currentDynamicDimmingCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(
      FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec,
    );
    return currentDynamicDimmingCustomization;
  }

  return {
    VocalGuidanceMode,
    onLivenessCheckPressed,
    onPhotoIDScanPressed,
    onEnrollUserPressed,
    onComplete,
    setLatestSessionResult,
    setIDScanResult,
    getLatestEnrollmentIdentifier,
    setLatestServerResult,
    setAppTheme,
    getCustomization,
    getLowLightCustomization,
    getDynamicDimmingCustomization,
  };
})();
